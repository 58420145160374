import React from "react";
import Modal from "components/Molecules/Portal/Portal";
import { Button } from "components/Atoms/Button";
import { Link } from "react-router-dom";
import { Roundtable } from "types";
import * as ROUTES from "constants/routes";

interface CompleteProfileModal {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  pendingRoundtable: Roundtable;
}
export const CompleteProfileModal: React.FC<CompleteProfileModal> = ({
  setShowModal,
  showModal,
  pendingRoundtable,
}) => {
  return (
    <Modal close={() => setShowModal(false)} open={showModal}>
      <h2>Missing profile information</h2>
      <p>
        It's important that other members know who they're chatting with. Please
        complete your profile before joining a roundtable.
      </p>
      <Link
        to={{
          pathname: ROUTES.EDIT_PROFILE,
          state: { pendingRoundtable: pendingRoundtable },
        }}
      >
        <Button primary>Complete My Profile</Button>
      </Link>
      <Button onClick={() => setShowModal(false)}>Cancel Request</Button>
    </Modal>
  );
};
