import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

import "./index.scss";

const Portal: React.FC<{}> = ({ children }) => {
  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    const portalRoot = document.getElementById("portal-root");
    portalRoot.appendChild(el);

    return () => {
      portalRoot.removeChild(el);
    };
  }, [el]);

  return createPortal(children, el);
};

const Modal: React.FC<{ close: () => void; open: boolean }> = ({
  children,
  close,
  open,
}) => {
  return (
    <Portal>
      {open ? (
        <div className="modal modal-wrapper">
          <div className="background" onClick={close} />
          <div className="modal-container">{children}</div>
        </div>
      ) : null}
    </Portal>
  );
};

export default Modal;
