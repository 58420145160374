import React, { useState, useEffect, useContext } from "react";

import "./index.scss";
import { Logo, XIcon } from "constants/icons";
import { toast } from "react-toastify";
import * as ROUTES from "../../constants/routes";
import Speaker from "static/home-speaker.png";
import Chat from "static/home-chat.png";
import Grow from "static/home-how-grow.png";
import Post from "static/home-how-post.png";
import Search from "static/home-how-search.png";
import ApplicationEnvelope from "static/home-application.png";
import CTA from "static/home-cta.png";
import useForm from "utils/FormHook";
import SignInGoogle from "containers/WelcomePage/SignInGoogle";
import SignInFacebook from "containers/WelcomePage/SignInFacebbok";
import { authApi } from "api/authApi";
import { useHistory } from "react-router-dom";
import { User } from "types";
import usersApi from "api/usersApi";
import { Input } from "components/Atoms/Input";
import { Button } from "components/Atoms/Button";
import { FirebaseContext } from "fb/context";

// const SignupForm = ({ status, message, subscribe }) => {
//   const INITIAL_STATE = {
//     email: "",
//     portfolio: "",
//     reason: "",
//   };

//   const onSubscribe = () =>
//     inputs.email &&
//     inputs.portfolio &&
//     inputs.email.indexOf("@") > -1 &&
//     subscribe({
//       EMAIL: inputs.email,
//       PORTFOLIO: inputs.portfolio,
//       REASON: inputs.reason,
//     });

//   const { inputs, handleInputChange, handleSubmit } = useForm(
//     onSubscribe,
//     INITIAL_STATE,
//   );
//   return (
//     <form onSubmit={handleSubmit}>
//       {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
//       {status === "error" && (
//         <div
//           style={{ color: "red" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === "success" && (
//         <div
//           style={{ color: "green" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       <input
//         name="email"
//         type="email"
//         onChange={handleInputChange}
//         placeholder="Your email"
//       />
//       <input
//         name="portfolio"
//         type="url"
//         onChange={handleInputChange}
//         placeholder="Link to your portfolio"
//       />
//       <input
//         name="reason"
//         type="text"
//         onChange={handleInputChange}
//         placeholder="What do you hope to get out of this"
//       />
//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// const SignupModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
//   <div className="modal">
//     <div className="header">
//       <h3>Signup</h3>
//       <div>
//         <button onClick={onClose}>X</button>
//       </div>
//     </div>
//     <MailchimpSubscribe
//       url={FORM_ACTION}
//       render={({ subscribe, status, message }) => (
//         <SignupForm status={status} message={message} subscribe={subscribe} />
//       )}
//     />
//     <p>
//       Already have an account? <span className="link">Login</span>
//     </p>
//   </div>
// );

const SignupModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <div className="modal">
    <div className="content">
      <button className="close" onClick={onClose}>
        <XIcon />
      </button>
      <div className="inner">
        <div className="header">
          <img
            src={ApplicationEnvelope}
            className="envelope"
            alt="Mail Envelope"
          />
          <h3>Sign up</h3>
          <p>Create a user to apply for the beta version of the app.</p>
        </div>
        <div className="actions">
          <SignInGoogle />
          <SignInFacebook />
        </div>
      </div>
      <div className="accent">
        <img
          src={ApplicationEnvelope}
          className="envelope"
          alt="Mail Envelope"
        />
      </div>
    </div>
  </div>
);

const SigninModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <div className="modal">
    <div className="content">
      <button className="close" onClick={onClose}>
        <XIcon />
      </button>
      <div className="inner">
        <div className="header">
          <img
            src={ApplicationEnvelope}
            className="envelope"
            alt="Mail Envelope"
          />
          <h3>Log in</h3>
          <p>Have you already been approved to join the beta? That's great!</p>
        </div>
        <div className="actions">
          <SignInGoogle />
          <SignInFacebook />
        </div>
      </div>
      <div className="accent">
        <img
          src={ApplicationEnvelope}
          className="envelope"
          alt="Mail Envelope"
        />
      </div>
    </div>
  </div>
);

const ApplicationModal: React.FC<{
  onClose: () => void;
  onUserUpdate: (user: User) => void;
  user: User;
}> = ({ onClose, user, onUserUpdate }) => {
  const INITIAL_STATE = {
    bio: user?.bio || "",
    portfolio: user?.portfolio || "",
    location: user?.location || "",
    title: user?.title || "",
    startDate: user?.startDate || "",
    intention: user?.intention || "",
  };

  const onSubmit = () => {
    usersApi.updateUserProfile(user.uid, inputs);
    const newUser = Object.assign(user, inputs);
    onUserUpdate(newUser);
    onClose();
    toast("Application updated, see you soon 💜");
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    onSubmit,
    INITIAL_STATE,
  );
  return (
    <div className="modal application">
      <div className="content">
        <button className="close" onClick={onClose}>
          <XIcon />
        </button>
        <div className="inner">
          <h3>Complete your application</h3>
          <p className="description">
            Your application is currently pending review. Speed up the process
            by telling us about yourself.
          </p>
          <form>
            <div className="fields">
              <div>
                <label>
                  A short bio<span className="required">*</span>
                </label>
                <Input
                  value={inputs.bio}
                  name="bio"
                  onChange={handleInputChange}
                  placeholder="What should people know"
                />
              </div>
              <div>
                <label>
                  A link to your portfolio<span className="required">*</span>
                </label>
                <Input
                  value={inputs.portfolio}
                  name="portfolio"
                  onChange={handleInputChange}
                  placeholder="Where can we see your work"
                  type="url"
                />
              </div>

              <div>
                <label>
                  Where are you from?<span className="required">*</span>
                </label>
                <Input
                  value={inputs.location}
                  name="location"
                  onChange={handleInputChange}
                  placeholder="Originally, or currently"
                />
              </div>
              <div>
                <label>
                  What's your current title?<span className="required">*</span>
                </label>
                <Input
                  value={inputs.title}
                  name="title"
                  onChange={handleInputChange}
                  placeholder="Supreme Design Overlord"
                />
              </div>
              <div>
                <label>When did you start your career?</label>
                <Input
                  value={inputs.startDate}
                  name="startDate"
                  onChange={handleInputChange}
                  placeholder="2020"
                  type="number"
                />
              </div>
              <div>
                <label>What's your intention for the app?</label>
                <Input
                  value={inputs.intention}
                  name="intention"
                  onChange={handleInputChange}
                  placeholder="e.g find a job"
                />
              </div>
              <p>
                <span className="required">*</span> Required to have your
                application reviewed
              </p>
            </div>
          </form>
        </div>
        <footer>
          <Button onClick={handleSubmit} primary type="submit">
            Save Changes
          </Button>
        </footer>
      </div>
    </div>
  );
};

const NewHome: React.FC<{ user: User }> = ({ user }) => {
  const [isApplying, setIsApplying] = useState(false);
  const [isSignin, setIsSignin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localUser, setLocalUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    authApi.doGetRedirectResult().then(redorectRes => {
      if (redorectRes.user) {
        usersApi.getUser(redorectRes.user.uid).then(dbRes => {
          dbRes.approved ? history.push(ROUTES.HOME) : setIsApplying(true);
        });
      }
      setIsLoading(false);
    });
  }, [history]);

  const { fbAnalytics } = useContext(FirebaseContext);

  const getActions = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    } else if (user && !user.approved) {
      return (
        <div className="actions">
          <button
            onClick={() => {
              fbAnalytics.logEvent("edit_application");
              setIsApplying(true);
            }}
          >
            Edit application
          </button>
        </div>
      );
    } else if (user && user.approved) {
      return (
        <div className="actions">
          <button onClick={() => history.push(ROUTES.HOME)}>Go to app</button>
        </div>
      );
    } else {
      return (
        <div className="actions">
          <button
            onClick={() => {
              fbAnalytics.logEvent("open_apply");
              setIsSignup(true);
            }}
          >
            Apply to join
          </button>
          <span>
            Already a member?{" "}
            <span
              className="link"
              onClick={() => {
                fbAnalytics.logEvent("open_login");
                setIsSignin(true);
              }}
            >
              Login
            </span>
          </span>
        </div>
      );
    }
  };

  return (
    <div className="home">
      {isSignin ? <SigninModal onClose={() => setIsSignin(false)} /> : null}
      {isSignup ? <SignupModal onClose={() => setIsSignup(false)} /> : null}
      {isApplying ? (
        <ApplicationModal
          user={localUser ? localUser : user}
          onUserUpdate={setLocalUser}
          onClose={() => setIsApplying(false)}
        />
      ) : null}
      <header>
        <div className="content">
          <Logo />
          <h1>
            Break out of your echo chamber<span className="hl">.</span>
          </h1>
          <p>
              A community of thoughtfully selected designers that interact in tiny
              groups called Roundtables, where in depth conversation can emerge
          </p>
          {getActions()}
        </div>
      </header>
      <section className="why">
        <div className="content">
          <span className="sub">Be part of a community</span>
          <h2>
            Have a place to call home<span className="hl">.</span>
          </h2>
          <p>
            Large networks optimize for growing the network, not the individual
            member. We want to create many small communities focused around a
            shared interest where members can grow
          </p>
        </div>
        <div className="img-container">
          <img src={Chat} alt="chat bubble" />
        </div>
      </section>
      <section className="why">
        <div className="content">
          <span className="sub">A place to share</span>
          <h2>
            Share what matters to you<span className="hl">.</span>
          </h2>
          <p>
            We want to create a space where it’s ok to be vulnerable, to share
            unpolished work in progress, personal frustrations and other things
            which are not appropriate for large social networks
          </p>
        </div>
        <div className="img-container">
          <img src={Speaker} alt="" />
        </div>
      </section>
      <section className="how">
        <h3>How it works</h3>
        <div className="content">
          <figure>
            <img src={Post} alt="Mailbox" />
            <div className="content">
              <span>Step 1</span>
              <h4>Apply to join our network</h4>
              <p>
                You start by submitting an application to join. The process is
                fast and allows us to scale the community in a way that keeps
                its integrity
              </p>
            </div>
          </figure>
          <figure>
            <img src={Search} alt="Magnifying glass" />
            <div className="content">
              <span>Step 2</span>
              <h4>Find your community</h4>
              <p>
                Once you’re in, you can say Hi at the Townhall, where the entire
                community gather and then browse for a Roundtable you could call
                home
              </p>
            </div>
          </figure>
          <figure>
            <img src={Grow} alt="Rocketship launching" />
            <div className="content">
              <span>Step 3</span>
              <h4>Grow with your Roundtable</h4>
              <p>
                You will get to know each member over time, slowly building the
                trust needed to create the space where all members can grow
              </p>
            </div>
          </figure>
        </div>
      </section>
      <section className="cta">
        <div className="content">
          <h2>Ready to join the community?</h2>
          <p>Submit an application today and join a community meant for you</p>
          {user && !user.approved ? (
            <button className="secondary" onClick={() => setIsApplying(true)}>
              Edit application
            </button>
          ) : (
            <button className="secondary" onClick={() => setIsSignup(true)}>
              Apply to join
            </button>
          )}
        </div>
        <div className="img-container">
          <img src={CTA} alt="Computer with design assets" />
        </div>
      </section>
    </div>
  );
};

export default NewHome;
