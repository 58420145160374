import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";

import { appConfig } from "../config";
export const fbApp = firebase.initializeApp(appConfig);
export const fbAuth = firebase.auth();
export const fbDb = firebase.firestore();
export const fbAnalytics = firebase.analytics();

fbDb.settings({});

fbDb.enablePersistence().catch(err => {
  if (err.code === "unimplemented") {
    throw new Error("Your browser does not support offline access");
  }
});

window["fb"] = fbDb;
