import React, { useContext, useState, useEffect } from "react";
import { compose } from "recompose";
import { withAuthorization } from "../Auth";
import RoundtableList from "./RoundtableList/";
import AppContext from "context/app-context";

import "./index.scss";
import { Header } from "components/Molecules/Header";
import { useSuggestedRoundtables } from "fb";
import { User, Roundtable } from "types";
import { useLocation } from "react-router-dom";
import useForm from "utils/FormHook";
import { SuggestionBox } from "./SuggestionBox";
import { CompleteProfileModal } from "./CompleteProfileModal";
import { BigPlus } from "constants/icons";
import { toast } from "react-toastify";

interface DiscoverProps {
  fbAuth?: firebase.auth.Auth;
}

const DiscoverPage: React.FC<DiscoverProps> = ({ fbAuth }) => {
  const [showModal, setShowModal] = useState(false);
  const [pendingRoundtable, setPendingRoundtable] = useState(null);
  const [showingSuggestion, setShowingSuggestion] = useState(false);
  const roundtablesAppApi = useContext(AppContext);
  const user: User = roundtablesAppApi.authUser;
  const location = useLocation();

  useEffect(() => {
    const toJoin = (location.state as any)?.joinPod;
    if (toJoin) {
      roundtablesAppApi.joinRoundtable(toJoin);
    }
  }, [location.state, roundtablesAppApi]);

  // TODO: Use authUser instead of fbAuth
  const suggestedRoundtables = useSuggestedRoundtables(fbAuth);
  const INITIAL_STATE = {
    suggestion: "",
  };

  const handleSendSuggestion = () => {
    roundtablesAppApi.sendSuggestion(inputs.suggestion);
    setInputs({ suggestion: "" });
    setShowingSuggestion(false);
    toast("Thanks for submitting your suggestion 🙏");
  };

  const { inputs, handleInputChange, handleSubmit, setInputs } = useForm(
    handleSendSuggestion,
    INITIAL_STATE,
  );

  if (!user) {
    return <div>...</div>;
  }

  const handleJoinRequest = (roundtable: Roundtable) => {
    if (!user.email || !user.title || !user.location || !user.bio) {
      setPendingRoundtable(roundtable);
      return setShowModal(true);
    }
    roundtablesAppApi.joinRoundtable(roundtable);
  };

  const handleCancelJoinRequest = (roundtable: Roundtable) => {
    roundtablesAppApi.cancelJoinRequest(roundtable);
  };

  const orderRoundtables = (roundtables: Roundtable[]) => {
    let orderedRoundtables = roundtables;
    const index = orderedRoundtables.findIndex(
      roundtable => roundtable.uid === "town-hall",
    );
    if (index > -1) {
      const townhall: Roundtable = orderedRoundtables.splice(index, 1)[0];
      orderedRoundtables.unshift(townhall);
    }
    orderedRoundtables.sort(function(x: Roundtable, y: Roundtable) {
      const inX = x.members.includes(roundtablesAppApi.authUser.uid);
      const inY = y.members.includes(roundtablesAppApi.authUser.uid);
      return inX === inY ? 0 : inX ? -1 : 1;
    });

    return orderedRoundtables;
  };
  return (
    <div className="discover-container">
      {showingSuggestion ? (
        <SuggestionBox
          setShowingSuggestion={setShowingSuggestion}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          inputs={inputs}
        />
      ) : null}
      <div className="discover-page page">
        <Header long title="Discover Roundtables" />
        <RoundtableList
          roundtables={orderRoundtables(suggestedRoundtables)}
          roundtablesAppApi={roundtablesAppApi}
          onJoinRequest={handleJoinRequest}
          onCancelJoinRequest={handleCancelJoinRequest}
        >
          <SuggestCard handleClick={() => setShowingSuggestion(true)} />
        </RoundtableList>
      </div>
      {showModal ? (
        <CompleteProfileModal
          setShowModal={setShowModal}
          showModal={showModal}
          pendingRoundtable={pendingRoundtable}
        />
      ) : null}
    </div>
  );
};

const SuggestCard: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  return (
    <div className="roundtable-card suggest-card" onClick={handleClick}>
      <div className="content">
        <BigPlus />
        <p>Suggest a topic</p>
      </div>
    </div>
  );
};

const condition = authUser => authUser?.approved;

export default compose<DiscoverProps, any>(withAuthorization(condition))(
  DiscoverPage,
);
