import React from "react";

import "./index.scss";

interface ButtonProps {
  children: any;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: "reset" | "submit";
  primary?: boolean;
  danger?: boolean;
}

export const Button = ({
  children,
  className = "",
  disabled,
  onClick,
  primary,
  danger,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick ? () => onClick() : undefined}
      className={`button ${primary ? "primary" : ""} ${
        danger ? "danger" : ""
      } ${className}`}
    >
      {children}
    </button>
  );
};
