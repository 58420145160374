import React from "react";
import { PinIcon, CalendarIcon } from "constants/icons";
import { Avatar } from "components/Atoms/Avatar";
import { User } from "types";

import "./index.scss";

const UserCard: React.FC<{
  user: User;
  wide?: boolean;
}> = ({ user, wide }) => (
  <section className={`user-card ${wide ? "wide" : ""}`}>
    <section className="intro">
      <Avatar image={user.photoURL} />
      <div className="details">
        <h4>{user.username}</h4>
        <sub>{user.title}</sub>
      </div>
    </section>

    {user.bio ? <p>{user.bio}</p> : <p>This member has not provided a bio</p>}
    <footer>
      {user.location ? (
        <div>
          <PinIcon />
          {user.location}
        </div>
      ) : null}
      {user.startDate ? (
        <div>
          <CalendarIcon />
          {user.startDate}
        </div>
      ) : null}
    </footer>
  </section>
);

export default UserCard;
