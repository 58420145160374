import {
  CREATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  LOAD_MESSAGES_SUCCESS,
  SUBSCRIBED_TO_MESSAGES,
  UNLOAD_MESSAGES_SUCCESS,
  LOAD_MY_ROUNDTABLES_SUCCESS,
  UNLOAD_MY_ROUNDTABLES_SUCCESS,
  SUBSCRIBED_TO_MY_ROUNDTABLES,
  ChatActionTypes,
  ChatState,
  UPDATE_ROUNDTABLE_SUCCESS,
  SET_ROUNDTABLE_SUCCESS,
} from "./types";
import { Reducer } from "redux";
import { List } from "immutable";

export const chatReducer: Reducer<Partial<ChatState>, ChatActionTypes> = (
  state = new ChatState(),
  action: ChatActionTypes,
) => {
  switch (action.type) {
    case UNLOAD_MESSAGES_SUCCESS:
      return { ...state, messagesLoaded: false, messages: null };

    case LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoaded: true,
        messages: List.of(...action.payload).reverse(),
      };

    case SUBSCRIBED_TO_MESSAGES: {
      return {
        ...state,
        messagesSubscription: action.payload,
      };
    }

    case UNLOAD_MY_ROUNDTABLES_SUCCESS:
      return { ...state, myRoundtables: null };

    case LOAD_MY_ROUNDTABLES_SUCCESS:
      return {
        ...state,
        myRoundtables: List.of(...action.payload),
      };

    case SUBSCRIBED_TO_MY_ROUNDTABLES:
      return {
        ...state,
        roundtablesSubscription: action.payload,
      };

    case CREATE_MESSAGE_SUCCESS:
    case UPDATE_MESSAGE_SUCCESS:
    case DELETE_MESSAGE_SUCCESS:
    case UPDATE_ROUNDTABLE_SUCCESS:
    case SET_ROUNDTABLE_SUCCESS:
      return state;

    default:
      return state;
  }
};
