import React, { useState, useEffect, useCallback } from "react";
import { withFirebase } from "../../fb/context";

const SIGN_IN_METHODS = [
  { id: "password", provider: null },
  { id: "google.com", provider: "googleProvider" },
];

const ScoialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) => {
  return isEnabled ? (
    <button onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </button>
  );
};

const DefaultLogicToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onUnlink,
  onLink,
}) => {
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");

  const onChange = event => {
    if (event.target.name === "passwordOne") {
      setPasswordOne(event.target.value);
    } else if (event.target.name === "passwordTwo") {
      setPasswordTwo(event.target.value);
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    onLink(passwordOne);
    setPasswordOne("");
    setPasswordTwo("");
  };

  const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

  return isEnabled ? (
    <button onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <form onSubmit={onSubmit}>
      <input
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder="New password"
      />
      <input
        name="passwordTwo"
        value={passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Repeat password"
      />
      <button disabled={isInvalid}>Link {signInMethod.id}</button>
    </form>
  );
};

const LoginManagementBase = ({ authApi, fbAuth, authUser }) => {
  const [activeSignInMethods, setActiveSignInMethods] = useState();
  const [error, setError] = useState();

  const fetchSignInMethods = useCallback(() => {
    fbAuth
      .fetchSignInMethodsForEmail(authUser.email)
      .then(activeMethods => {
        setActiveSignInMethods(activeMethods);
        setError(null);
      })
      .catch(err => setError(err));
  }, [authUser.email, fbAuth]);

  const onSocialLoginLink = provider => {
    fbAuth.currentUser
      .linkWithPopup(authApi[provider])
      .then(fetchSignInMethods())
      .catch(error => setError(error));
  };

  const onUnlink = providerId => {
    fbAuth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods())
      .catch(err => setError(err));
  };

  const onDefaultLogicLink = password => {
    const credential = authApi.emailAuthProvider.credential(
      authUser.email,
      password,
    );

    fbAuth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(fetchSignInMethods())
      .catch(error => setError(error));
  };

  useEffect(() => {
    fetchSignInMethods();
  }, [fetchSignInMethods]);

  return (
    <div>
      <h3>Login Management</h3>
      <ul>
        {SIGN_IN_METHODS.map(method => {
          const onlyOneLeft = activeSignInMethods
            ? activeSignInMethods.length === 1
            : false;

          const isEnabled = activeSignInMethods
            ? activeSignInMethods.includes(method.id)
            : false;
          return (
            <li key={method.id}>
              {method.id === "password" ? (
                <DefaultLogicToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={method}
                  onUnlink={onUnlink}
                  onLink={onDefaultLogicLink}
                />
              ) : (
                <ScoialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={method}
                  onLink={onSocialLoginLink}
                  onUnlink={onUnlink}
                />
              )}
            </li>
          );
        })}
      </ul>
      {error ? <p>{error.message}</p> : null}
    </div>
  );
};

export default withFirebase(LoginManagementBase);
