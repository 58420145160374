import React from "react";

import "./index.scss";

interface RoundtableMenuBlockProps {
  className?: string;
  name?: string;
  icon?: any;
}

export const RoundtableMenuBlock: React.FC<RoundtableMenuBlockProps> = ({
  name,
  className,
  icon,
}) => {
  return (
    <div className={`roundtable-menu-block ${className}`}>
      {name ? <span className="sign">{name}</span> : null}
      {icon ? <span className="sign">{icon()}</span> : null}
    </div>
  );
};
