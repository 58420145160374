import { fbDb } from "fb/firebase";
import "firebase/firestore";
import { Message } from "types/index";
import {
  CREATE_MESSAGE_ERROR,
  CREATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  LOAD_MESSAGES_SUCCESS,
  UNLOAD_MESSAGES_SUCCESS,
  UPDATE_MESSAGE_ERROR,
  UPDATE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  SUBSCRIBED_TO_MESSAGES,
  KICK_USER_SUCCESS,
  KICK_USER_ERROR,
  UPDATE_ROUNDTABLE_SUCCESS,
  UPDATE_ROUNDTABLE_ERROR,
  SET_ROUNDTABLE_SUCCESS,
  SET_ROUNDTABLE_ERROR,
  LOAD_MY_ROUNDTABLES_SUCCESS,
  UNLOAD_MY_ROUNDTABLES_SUCCESS,
  SUBSCRIBED_TO_MY_ROUNDTABLES,
  ChatState,
  RoundtableUpdateBody,
} from "./types";
import { firestore } from "firebase";

// TODO: Refactor chatActions name to roundtableActions

export function loadMessagesForRoundtable(roundtableId: string, limit: number) {
  return dispatch => {
    const collection = fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("messages");

    const unsubscribe = collection
      .limit(limit)
      .orderBy("created", "desc")
      .onSnapshot(snapshot => {
        const messages = snapshot.docs.map(messageDocSnapshot => {
          return { ...messageDocSnapshot.data(), id: messageDocSnapshot.id };
        });
        dispatch({ type: LOAD_MESSAGES_SUCCESS, payload: messages });
      });

    dispatch({
      type: SUBSCRIBED_TO_MESSAGES,
      payload: { collection, unsubscribe },
    });
  };
}

export function unloadMessages() {
  return async (dispatch, getState: () => { chat: ChatState }) => {
    const { unsubscribe } = getState().chat.messagesSubscription;
    unsubscribe();
    dispatch({
      type: UNLOAD_MESSAGES_SUCCESS,
    });
  };
}

export function loadMyRoundtables(userId: string) {
  return dispatch => {
    const unsubscribe = fbDb.collection("roundtables").onSnapshot(snapshot => {
      const allRoundtables = [];
      snapshot.forEach(doc =>
        allRoundtables.push({ uid: doc.id, ...doc.data() }),
      );
      const joinedRoundtables = allRoundtables.reduce((acc, roundtable) => {
        const userMember = roundtable.members.find(uid => uid === userId);
        if (userMember) {
          acc.push(roundtable);
        }
        return acc;
      }, []);
      dispatch({
        type: LOAD_MY_ROUNDTABLES_SUCCESS,
        payload: joinedRoundtables,
      });
    });
    dispatch({
      type: SUBSCRIBED_TO_MY_ROUNDTABLES,
      payload: { unsubscribe },
    });
  };
}

export function unloadMyRoundtables() {
  return async (dispatch, getState: () => { chat: ChatState }) => {
    const { unsubscribe } = getState().chat.roundtablesSubscription;
    unsubscribe();
    dispatch({
      type: UNLOAD_MY_ROUNDTABLES_SUCCESS,
    });
  };
}

export function createMessage(message: Message) {
  return async (dispatch, getState: () => { chat: ChatState }) => {
    try {
      const { collection } = getState().chat.messagesSubscription;
      const add = await collection.add(message);
      const added = await add.get();
      const payload = added.data();

      dispatch({
        id: add.id,
        type: CREATE_MESSAGE_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: CREATE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };
}

export function deleteMessage(id: string) {
  return async (dispatch, getState: () => { chat: ChatState }) => {
    try {
      const { collection } = getState().chat.messagesSubscription;
      await collection.doc(id).delete();
      dispatch({
        type: DELETE_MESSAGE_SUCCESS,
        payload: id,
      });
    } catch (error) {
      dispatch({
        type: DELETE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };
}

export function updateRoundtable(id: string, body: RoundtableUpdateBody) {
  return async dispatch => {
    try {
      await fbDb
        .collection("roundtables")
        .doc(id)
        .update(body);
      dispatch({ type: UPDATE_ROUNDTABLE_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: UPDATE_ROUNDTABLE_ERROR, payload: error });
    }
  };
}

export function setRoundtable(id: string, body: RoundtableUpdateBody, merge?: boolean) {
  return async dispatch => {
    try {
      await fbDb
        .collection("roundtables")
        .doc(id)
        .set(body, { merge: !!merge });
      dispatch({ type: SET_ROUNDTABLE_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: SET_ROUNDTABLE_ERROR, payload: error });
    }
  };
}

export function updateMessage(id: string, body: string) {
  return async (dispatch, getState: () => { chat: ChatState }) => {
    try {
      const { collection } = getState().chat.messagesSubscription;
      await collection.doc(id).update({ body });
      dispatch({
        type: UPDATE_MESSAGE_SUCCESS,
        payload: id,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_ERROR,
        payload: error,
      });
    }
  };
}

export function kickUser(roundtableId: string, userId: string) {
  return dispatch => {
    try {
      fbDb
        .collection("roundtables")
        .doc(roundtableId)
        .update({ members: firestore.FieldValue.arrayRemove(userId) });

      dispatch({ type: KICK_USER_SUCCESS, payload: userId });
    } catch (error) {
      dispatch({
        type: KICK_USER_ERROR,
        payload: error,
      });
    }
  };
}
