import React from "react";
import Sidebar from "../Sidebar";

import "./index.scss";
import { User, Roundtable } from "types";

const AppLayout: React.FC<{
  user: User;
  roundtables: Roundtable[];
}> = ({ children, user, roundtables, ...props }) => {
  return (
    <div className={"page-template"} {...props}>
      <Sidebar roundtables={roundtables} user={user} />

      <div {...props} className={"page-content"}>
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
