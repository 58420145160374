import React from "react";
import { Header } from "components/Molecules/Header";
import { Button } from "components/Atoms/Button";
import PageModal from "components/Molecules/PageModal";
import { Input } from "components/Atoms/Input";
import { XIcon } from "constants/icons";


export const SuggestionBox = ({ setShowingSuggestion, handleSubmit, inputs, handleInputChange, }: {
  setShowingSuggestion: (value: React.SetStateAction<boolean>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  inputs: any;
  handleInputChange: (e: any) => void;
}) => {
  return (<PageModal className="suggest">
    <Header title="Suggest a topic">
      <span className="link" onClick={() => setShowingSuggestion(false)}>
        <XIcon />
      </span>
    </Header>
    <section className="content">
      <p>
        Let us know which other Roundtables we should open. This is your
        chance to shape the direction of our community.
        </p>
      <form onSubmit={handleSubmit}>
        <Input value={inputs.suggestion} placeholder="Web Design, development, etc.." name="suggestion" onChange={handleInputChange} />
        <Button primary>Submit suggestion</Button>
      </form>
    </section>
  </PageModal>);
};
