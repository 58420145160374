import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppContext from "context/app-context";
import { withFirebase } from "../../fb/context";
import * as ROUTES from "../../constants/routes";
import { User } from "types";

const withAuthorization = (
  condition: (authUser: User) => boolean,
) => Component => {
  const WithAuthorization = props => {
    const { authApi } = props;
    const history = useHistory();

    useEffect(() => {
      const unsubscribe = authApi.onAuthListener(
        authUser => {
          if (!condition(authUser)) {
            history.push(ROUTES.WELCOME);
          }
        },
        () => {
          history.push(ROUTES.WELCOME);
        },
      );

      return () => unsubscribe();
    }, [history, authApi]);

    return (
      <AppContext.Consumer>
        {roundtablesAppApi =>
          condition(roundtablesAppApi.authUser) ? (
            <Component {...props} />
          ) : null
        }
      </AppContext.Consumer>
    );
  };

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
