import React, { useState } from "react";
import { authApi } from "api/authApi";
import { Button } from "components/Atoms/Button";
import { GoogleIcon } from "constants/icons";

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
An account with this E-Mail address already exists. Try to login using your Facebook account instead and associate your Google account on
your personal account page.
`;

const SignInGoogle: React.FC<{}> = () => {
  const [error, setError] = useState();

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await authApi.doSignInWithGoogle();
      setError(null);
    } catch (error) {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      setError(error);
    }
  };

  return (
    <div className="sign-in">
      <form onSubmit={onSubmit}>
        <Button primary>
          <section className="btn-content">
            <GoogleIcon /> <span>Login w/ Google</span>
          </section>
        </Button>
        {error && <p className="error">{(error as any).message}</p>}
      </form>
    </div>
  );
};

export default SignInGoogle;
