import { Message, Roundtable } from "types";
import "firebase/firestore";
import { firestore } from "firebase/app";
import { List } from "immutable";

export const LOAD_MESSAGES_SUCCESS = "CHAT/LOAD_MESSAGES_SUCCESS";
export const UNLOAD_MESSAGES_SUCCESS = "CHAT/UNLOAD_MESSAGES_SUCCESS";
export const SUBSCRIBED_TO_MESSAGES = "CHAT/SUBSCRIBED_TO_MESSAGES";

export const LOAD_MY_ROUNDTABLES_SUCCESS = "CHAT/LOAD_MY_ROUNDTABLES_SUCCESS";
export const UNLOAD_MY_ROUNDTABLES_SUCCESS =
  "CHAT/UNLOAD_MY_ROUNDTABLES_SUCCESS";
export const SUBSCRIBED_TO_MY_ROUNDTABLES = "CHAT/SUBSCRIBED_TO_MY_ROUNDTABLES";

export const DELETE_MESSAGE_SUCCESS = "CHAT/DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "CHAT/DELETE_MESSAGE_ERROR";

export const CREATE_MESSAGE_SUCCESS = "CHAT/CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_ERROR = "CHAT/CREATE_MESSAGE_ERROR";

export const UPDATE_MESSAGE_SUCCESS = "CHAT/UPDATE_MESSAGE_SUCCESS";
export const UPDATE_MESSAGE_ERROR = "CHAT/UPDATE_MESSAGE_ERROR";

export const KICK_USER_SUCCESS = "CHAT/KICK_USER_SUCCESS";
export const KICK_USER_ERROR = "CHAT/KICK_USER_ERROR";

export const UPDATE_ROUNDTABLE_SUCCESS = "CHAT/UPDATE_ROUNDTABLE_SUCCESS";
export const UPDATE_ROUNDTABLE_ERROR = "CHAT/UPDATE_ROUNDTABLE_ERROR";

export const SET_ROUNDTABLE_SUCCESS = "CHAT/SET_ROUNDTABLE_SUCCESS";
export const SET_ROUNDTABLE_ERROR = "CHAT/SET_ROUNDTABLE_ERROR";

export class ChatState {
  messages: List<Message>;
  messagesLoaded: boolean;
  messagesSubscription: MessagesSubscription;
  myRoundtables: List<Roundtable>;
  roundtablesSubscription: RoundtablesSubscription;
}

/* Load Messages */
export interface MessagesSubscription {
  unsubscribe();
  collection: firestore.CollectionReference;
}

interface LoadMessagesAction {
  type: typeof LOAD_MESSAGES_SUCCESS;
  payload: Message[];
}

interface SubscribedToMessagesAction {
  type: typeof SUBSCRIBED_TO_MESSAGES;
  payload: MessagesSubscription;
}

interface UnloadMessageSuccessAction {
  type: typeof UNLOAD_MESSAGES_SUCCESS;
}

/* Load My Roundtables */
export interface RoundtablesSubscription {
  unsubscribe();
}
interface LoadMyRoundtablesAction {
  type: typeof LOAD_MY_ROUNDTABLES_SUCCESS;
  payload: Roundtable[];
}

interface SubscribedToMyRoundtablesAction {
  type: typeof SUBSCRIBED_TO_MY_ROUNDTABLES;
  payload: RoundtablesSubscription;
}

interface UnloadMyRoundtablesSuccessAction {
  type: typeof UNLOAD_MY_ROUNDTABLES_SUCCESS;
}

/* Message Actions */
interface CreateMessageSuccessAction {
  type: typeof CREATE_MESSAGE_SUCCESS;
  payload: Message;
}

interface DeleteMessageSuccessAction {
  type: typeof DELETE_MESSAGE_SUCCESS;
  payload: Message;
}

interface UpdateMessageSuccessAction {
  type: typeof UPDATE_MESSAGE_SUCCESS;
  payload: Message;
}

interface KickUserAction {
  type: typeof KICK_USER_SUCCESS;
  payload: string;
}

interface UpdateRoundtableAction {
  type: typeof UPDATE_ROUNDTABLE_SUCCESS;
  payload: string;
}

interface SetRoundtableAction {
  type: typeof SET_ROUNDTABLE_SUCCESS;
  payload: string;
}

export interface RoundtableUpdateBody {
  name?: string;
  tag?: string;
  description?: string;
  requests?: Request[];
}

export interface MyRoundtablesType {
  joinedRoundtables: Roundtable[];
  adminRoundtables: Roundtable[];
}

export type ChatActionTypes =
  | SubscribedToMyRoundtablesAction
  | LoadMyRoundtablesAction
  | UnloadMyRoundtablesSuccessAction
  | SubscribedToMessagesAction
  | LoadMessagesAction
  | UnloadMessageSuccessAction
  | UpdateRoundtableAction
  | SetRoundtableAction
  | CreateMessageSuccessAction
  | DeleteMessageSuccessAction
  | UpdateMessageSuccessAction
  | KickUserAction;
