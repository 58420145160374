// import * as firebase from "firebase";
import { fbDb } from "fb/firebase";
// import { useState, useRef, useEffect } from "react";
import { User } from "types";

interface ProfileDataUpdate {
  // Fields allowed when updating data
  name?: string;
  username?: string;
  title?: string;
  location?: string;
  roles?: string;
  created?: string;
  startDate?: string;
  intention?: string;
}
class UsersApi {
  public authUser: User;

  public getUser(userId: string) {
    const user = fbDb
      .collection("users")
      .doc(userId)
      .get()
      .then(snap => snap.data());

    return user;
  }

  // static subscribeUser(userId: string) {
  //   return fbDb
  //     .collection("users")
  //     .doc(userId)
  //     .onSnapshot(snapshot => {
  //       return snapshot.data();
  //     });
  // }
  public async getUsers(userIds: string[]) {
    try {
      const querySnapshots = await Promise.all(
        userIds.map((userId: string) => {
          return fbDb
            .collection("users")
            .doc(userId)
            .get();
        }),
      );
      const users = querySnapshots.map(snap => {
        const userObj = snap.data();
        userObj.uid = snap.id;
        return userObj;
      });
      return users;
    } catch (error) {
      throw new Error(error);
    }
  }
  public updateUserProfile(userId: string, data: ProfileDataUpdate) {
    return fbDb
      .collection("users")
      .doc(userId)
      .set(data, { merge: true });
  }

  public approveUser(uid: string) {
    return fbDb
      .collection("users")
      .doc(uid)
      .set({ approved: true }, { merge: true });
  }

  public unapproveUser(uid: string) {
    return fbDb
      .collection("users")
      .doc(uid)
      .set({ approved: false }, { merge: true });
  }

  public approveAllUsers() {
    return fbDb
      .collection("users")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.set(
            {
              approved: true,
            },
            { merge: true },
          );
        });
      });
  }
}

export default new UsersApi();
