import React from "react";
import { MenuIcon } from "constants/icons";
import { XIcon } from "constants/icons";

import "./index.scss";

interface HeaderProps {
  onClick?: () => void;
  title: string;
  icon?: () => JSX.Element;
  x?: boolean;
  long?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onClick,
  title,
  icon,
  x,
  children,
  long,
}) => {
  const renderIcon = () => {
    if (icon) {
      return icon;
    } else if (x) {
      return <XIcon />;
    }
    return <MenuIcon />;
  };
  return (
    <header className={`header-root ${long ? "long" : null}`}>
      <h1>{title}</h1>
      {onClick ? (
        <button className="link icon" onClick={() => onClick()}>
          {renderIcon()}
        </button>
      ) : null}
      {children && <div className="actions">{children}</div>}
    </header>
  );
};
