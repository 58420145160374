import React from "react";
import { Avatar } from "components/Atoms/Avatar";

import "./index.scss";
import { SendIcon } from "constants/icons";
import { Discussion, User, Comment } from "types";
import moment from "moment";
import useForm from "utils/FormHook";

interface DiscussionItemProps {
  discussion: Discussion;
  isAuthor?: boolean;
  isSelected?: boolean;
  members: User[];
  onDiscussionClick?: (discussion: Discussion) => void;
  onSelectComment?: (discussion: Discussion, comment: Comment) => void;
  onSelectMember: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    member: User,
  ) => void;
  onSubmitComment?: (discussionId: string, value: string) => void;
  selectedComment?: { discussion: Discussion; commentId: string };
}

export const DiscussionItem: React.FC<DiscussionItemProps> = ({
  isSelected,
  members,
  discussion,
  onDiscussionClick,
  isAuthor,
  onSubmitComment,
  onSelectComment,
  selectedComment,
  onSelectMember,
}) => {
  const author = members.find(member => member.uid === discussion.userId);

  const INITIAL_STATE = { comment: "" };

  const handleSubmitComment = () => {
    onSubmitComment(discussion.uid, inputs.comment);
    setInputs({ comment: "" });
  };

  const { inputs, handleInputChange, handleSubmit, setInputs } = useForm(
    handleSubmitComment,
    INITIAL_STATE,
  );

  const getUser = (uid: string) => {
    return members.find(member => member.uid === uid);
  };

  let t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(discussion.created.seconds);

  return (
    <div
      className={`discussion-item ${isAuthor ? "author" : null} ${
        isSelected ? "selected" : null
      }`}
    >
      <div className="post" onClick={() => onDiscussionClick(discussion)}>
        <header>
          <span
            className="member-details"
            onClick={e => onSelectMember(e, author)}
          >
            <Avatar image={author?.photoURL} />
            <p className="name">{author?.username}</p>
          </span>
          <p className="time">{moment(t).calendar()}</p>
        </header>
        <p>{discussion.value}</p>
      </div>
      <div className="comments">
        <ul className="comment-list">
          {discussion.comments.map(comment => (
            <li
              key={comment.uid}
              onClick={() => onSelectComment(discussion, comment)}
              className={
                selectedComment &&
                selectedComment.commentId === comment.uid &&
                selectedComment.discussion.uid === discussion.uid
                  ? "selected"
                  : null
              }
            >
              <strong
                className="name"
                onClick={e => onSelectMember(e, getUser(comment.userId))}
              >
                {getUser(comment.userId)?.username}
              </strong>{" "}
              {comment.value}
            </li>
          ))}
        </ul>
        {onSubmitComment ? (
          <form
            name="send-comment"
            className="send-comment"
            onSubmit={handleSubmit}
          >
            <input
              name="comment"
              className="submit-comment"
              placeholder="Add a comment"
              value={inputs.comment}
              onChange={handleInputChange}
            />
            <button type="submit">
              <SendIcon />
            </button>
          </form>
        ) : null}
      </div>
    </div>
  );
};
