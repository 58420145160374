import React from "react";

import { withFirebase } from "../../fb/context";
import { authApi } from "api/authApi";

const DeleteUserButton = () => (
  <button className="link danger" onClick={() => authApi.doDeleteSelf()}>
    <span role="img" aria-label="warning">
      ⚠️
    </span>{" "}
    Delete Account
  </button>
);

export default withFirebase(DeleteUserButton);
