import React from "react";
import { Link } from "react-router-dom";
import { SignOutButton } from "../../Auth";
import { Header } from "components/Molecules/Header";
import { XIcon } from "constants/icons";
import { EDIT_PROFILE } from "constants/routes";
import PageModal from "components/Molecules/PageModal";
export const AccountMenu: React.FC<{
  toggleMenu: () => void;
}> = ({ toggleMenu }) => (
  <PageModal className="menu">
    <Header title="Account Settings">
      <button className="link" onClick={() => toggleMenu()}>
        <XIcon />
      </button>
    </Header>
    <ul className="links">
      <li>
        <Link className="link" to={EDIT_PROFILE}>
          Update Profile
        </Link>
      </li>
      <li>
        <SignOutButton />
      </li>
    </ul>
  </PageModal>
);
