import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducers from "./reducers";

const middlewares = [thunkMiddleware];
const middleWareEnhancer = applyMiddleware(...middlewares);

export const store = createStore(
  rootReducers,
  composeWithDevTools(middleWareEnhancer)
);

export default function configureStore() {

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept(
      './reducers', () => store.replaceReducer(rootReducers))
  }

  return store;
}
