import React from "react";

import { withFirebase } from "../../fb/context";
import { authApi } from "api/authApi";

const SignOutButton = () => (
  <button className="link" onClick={() => authApi.doSignOut()}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
