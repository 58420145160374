import { combineReducers } from "redux";
import { chatReducer } from "./chat/reducers";
import { usersReducer } from "./users/reducers";

import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";

const rootReducers = combineReducers({
  chat: chatReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducers>;
// TODO: is useRoundtablesSelector the best name, seeing as we're also using it for users?
export const useRoundtablesSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export default rootReducers;
