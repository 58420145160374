import React from "react";
import { compose } from "recompose";
import { Switch, Route } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { withAuthorization } from "../Auth";
import UserItem from "./UserItem";
import UserList from "./UserList";

const AdminPage = () => {
  return (
    <div style={{ height: "100%" }}>
      <h1>Welcome, admin</h1>
      <Switch>
        <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
        <Route exact path={ROUTES.ADMIN} component={UserList} />
      </Switch>
    </div>
  );
};

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition))(AdminPage);
