import React from "react";
import { User } from "types";
import { Avatar } from "components/Atoms/Avatar";

import "./index.scss";
import { XIcon, SendIcon } from "constants/icons";
import { Button } from "components/Atoms/Button";

import RoundtablesAppApi from "api/roundtablesAppApi";

const DiscussionInput: React.FC<{
  author: User;
  roundtablesAppApi: RoundtablesAppApi;
  value: string;
  inputName?: string;

  onDiscardDiscussion: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}> = ({
  author,
  inputName,
  onDiscardDiscussion,
  onSubmit,
  value,
  onInputChange,
}) => {
  return (
    <div className="discussion-input post">
      <header>
        <Avatar image={author.photoURL} />
        <p className="name">{author.username}</p>
      </header>
      <form onSubmit={onSubmit}></form>
      <textarea
        value={value}
        onChange={onInputChange}
        name={inputName ? inputName : "newDiscussion"}
        placeholder="Start a discussion"
        autoFocus
      />

      <div className="actions">
        <span className="link cancel" onClick={() => onDiscardDiscussion()}>
          <XIcon />
        </span>
        <Button type="submit" className="send" primary>
          <SendIcon />
        </Button>
      </div>
    </div>
  );
};

export default DiscussionInput;
