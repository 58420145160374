import { useEffect, useState, useRef } from "react";
import { Roundtable, User, Request, Comment, Discussion } from "types";
import { getTime } from "fb/utils";
import { fbDb } from "fb";
import firebase from "firebase";
// import usersApi from "api/usersApi";

export default class RoundtablesAppApi {
  public authUser: User;

  /**** Roundtables 🏓 ****/

  getRoundtable(roundtableId: string) {
    const roundtable = fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .get()
      .then(snap => snap.data());

    return roundtable;
  }

  async isMemberOfRoundtableById(roundtableId: string) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    let roundtableData = await this.getRoundtable(roundtableId).then(
      roundtableObj => roundtableObj,
    );

    return roundtableData.members.some(uid => uid === this.authUser.uid);
  }

  isMemberOfRoundtable(roundtable: Roundtable) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    return roundtable.members.some(uid => uid === this.authUser.uid);
  }
  isMemberPendingApproval(roundtable: Roundtable) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    if (roundtable.requests === undefined || roundtable.requests === []) {
      return false;
    }

    return roundtable.requests.some(
      request => request.userUid === this.authUser.uid,
    );
  }

  leaveRoundtable(roundtable: Roundtable) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    const newMembers = roundtable.members.filter(
      member => member !== this.authUser.uid,
    );

    fbDb
      .collection("roundtables")
      .doc(roundtable.uid)
      .set({ members: newMembers }, { merge: true });
  }

  /**** Discussions 🗣 ****/

  registerToDiscussions(
    roundtableId: string,
    cb: (
      doc: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    ) => void,
  ) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }
    const unsubscribe = fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .orderBy("created", "desc")
      .onSnapshot(cb);

    return unsubscribe;
  }

  createDiscussion(roundtableId: string, value: string) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }
    fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .add({
        userId: this.authUser.uid,
        value,
        created: getTime(),
        comments: [],
      });
  }

  deleteDiscussion(roundtableId: string, discussionId: string) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .doc(discussionId)
      .delete();
  }
  updateDiscussion(roundtableId: string, discussionId: string, value: string) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .doc(discussionId)
      .set({ value }, { merge: true });
  }

  /**** Comments 💬 ****/
  createComment(roundtableId: string, discussionId: string, value: Comment) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .doc(discussionId)
      .set(
        { comments: firebase.firestore.FieldValue.arrayUnion(value) },
        { merge: true },
      );
  }

  deleteComment(
    roundtableId: string,
    discussion: Discussion,
    commentId: string,
  ) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }
    const comments = discussion.comments.filter(
      comment => comment.uid !== commentId,
    );

    fbDb
      .collection("roundtables")
      .doc(roundtableId)
      .collection("discussions")
      .doc(discussion.uid)
      .set({ comments }, { merge: true });
  }

  /**** Requests ****/

  getRequests(
    roundtables: Roundtable[],
  ): { request: Request; roundtable: Roundtable }[] {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    const adminRoundtables = roundtables.filter(roundtable =>
      roundtable.admins.includes(this.authUser.uid),
    );

    const newRequests = adminRoundtables.reduce<
      { request: Request; roundtable: Roundtable }[]
    >((acc, roundtable) => {
      //TODO: make sure requests is an empty array by default
      if (roundtable.requests && roundtable.requests.length > 0) {
        for (const request of roundtable.requests) {
          acc.push({ request, roundtable });
        }
      }
      return acc;
    }, []);

    return newRequests;
  }

  joinRoundtable(roundtable: Roundtable) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }
    if (this.isMemberPendingApproval(roundtable)) {
      return console.log(`you already asked to join this roundtable`);
    }

    let newRequests: Request[];

    if (roundtable.requests) {
      newRequests = [
        ...roundtable.requests,
        {
          userUid: this.authUser.uid,
          roundtableUid: roundtable.uid,
          created: getTime(),
        },
      ];
    } else {
      newRequests = [
        {
          userUid: this.authUser.uid,
          roundtableUid: roundtable.uid,
          created: getTime(),
        },
      ];
    }

    fbDb
      .collection("roundtables")
      .doc(roundtable.uid)
      .set({ requests: newRequests }, { merge: true });
  }

  cancelJoinRequest(roundtable: Roundtable) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }
    const newRequests = roundtable.requests.filter(
      request => request.userUid !== this.authUser.uid,
    );

    fbDb
      .collection("roundtables")
      .doc(roundtable.uid)
      .set({ requests: newRequests }, { merge: true });
  }

  rejectJoinRequest(roundtable: Roundtable, requestingUserUid: string) {
    const removeIndex = roundtable.requests
      .map(request => request.userUid)
      .indexOf(requestingUserUid);

    roundtable.requests.splice(removeIndex, 1);

    fbDb
      .collection("roundtables")
      .doc(roundtable.uid)
      .set(roundtable);
  }

  approveJoinRequest(roundtable: Roundtable, requestingUserUid: string) {
    roundtable.members.push(requestingUserUid);

    const removeIndex = roundtable.requests
      .map(request => request.userUid)
      .indexOf(requestingUserUid);

    roundtable.requests.splice(removeIndex, 1);

    fbDb
      .collection("roundtables")
      .doc(roundtable.uid)
      .set(roundtable);
  }

  sendSuggestion(value: string) {
    if (!this.authUser) {
      throw new Error(`you must be logged in`);
    }

    const suggestion = {
      userId: this.authUser.uid,
      value,
      created: getTime(),
    };
    fbDb.collection("suggestions").add(suggestion);
  }
}

export const useRoundtablesAppApi = authApi => {
  const [authUser, updateAuthUser] = useState(null);
  const { current: roundtablesAppApi } = useRef(new RoundtablesAppApi());

  roundtablesAppApi.authUser = authUser;

  useEffect(() => {
    const unsubscribe = authApi.onAuthListener(
      authUser => {
        updateAuthUser(authUser);
      },
      () => {
        updateAuthUser(null);
      },
    );
    return () => unsubscribe();
  }, [authApi]);
  return roundtablesAppApi;
};
