import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { FirebaseContext } from "./fb/context";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./state";
import { fbDb, fbAuth, fbApp, fbAnalytics } from "fb/firebase";
import { authApi } from "api/authApi";


import "./index.scss";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider
      value={{
        fbDb,
        fbAuth,
        fbApp,
        authApi,
        fbAnalytics,
      }}
    >
      <Router>
        <App />
      </Router>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
