import { User } from "types";
import { List } from "immutable";
import { firestore } from "firebase/app";

export const SUBSCRIBED_TO_USERS = "USERS/SUBSCRIBED_TO_USERS";
export const GET_USER_SUCCESS = "USERS/GET_USER_SUCCESS";
export const GET_USER_ERROR = "USERS/GET_USER_ERROR";
export const GET_USERS_SUCCESS = "USERS/GET_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "USERS/LOAD_USERS_ERROR";
export const UPDATE_USER_SUCCESS = "USERS/UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "USERS/UPDATE_USER_ERROR";
export const DELETE_USER_SUCCESS = "USERS/DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "USERS/DELETE_USER_ERROR";

export class UsersState {
  users: List<User>;
  usersSubscription: Subscription;
}
export interface Subscription {
  unsubscribe();
  collection: firestore.CollectionReference;
  usersSubscription: Subscription;
}

interface SubscribedToUsersAction {
  type: typeof SUBSCRIBED_TO_USERS;
  payload: Subscription;
}

interface GetUserAction {
  type: typeof GET_USER_SUCCESS;
  payload: User;
}
interface GetUsersAction {
  type: typeof GET_USERS_SUCCESS;
  payload: User[];
}

interface UpdateUserAction {
  type: typeof UPDATE_USER_SUCCESS;
  payload: UserUpdateBody;
}

interface DeleteUserAction {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

export interface UserUpdateBody {
  name?: string;
  username?: string;
  title?: string;
  location?: string;
  roles?: string;
  created?: string;
  startDate?: number;
  intention?: string;
}

export type UsersActionType =
  | DeleteUserAction
  | UpdateUserAction
  | SubscribedToUsersAction
  | GetUserAction
  | GetUsersAction;
