import React from "react";

interface RoundtableButtonProps {
  isMember: boolean;
  isPendingApproval: boolean;
}

export const RoundtableButton: React.FC<RoundtableButtonProps> = ({
  isMember,
  isPendingApproval,
}) => {
  if (isMember) {
    return <button className="roundtable-button">Enter Roundtable</button>;
  } else if (isPendingApproval) {
    return <button className="roundtable-button">Cancel request</button>;
  } else {
    return <button className="roundtable-button">Request to join</button>;
  }
};
