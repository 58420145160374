import React from "react";
import RoundtablesAppApi from "api/roundtablesAppApi";
import { DiscussionItem } from "../DiscussionItem";
import {  Discussion, Comment, User } from "types";

import Loader from "components/Atoms/Loader";
import DiscussionInput from "../DiscussionInput";

interface DiscussionListProps {
  discussions: Discussion[];
  editValue: { editDiscussion: string };
  isEditMode: boolean;
  members: User[];
  roundtablesAppApi: RoundtablesAppApi;
  selectedComment: { discussion: Discussion; commentId: string };
  selectedDiscussion: Discussion;

  onDiscardEdit: () => void;
  onDiscussionClick: (discussion: Discussion) => void;
  onEditInputChange: (e: any) => void;
  onEditSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onSelectMember: (e:React.MouseEvent<HTMLSpanElement, MouseEvent>, member: User)=>void;
  onSelectComment: (discussion: Discussion, comment: Comment) => void;
  onSubmitComment: (discussionId: string, value: string) => void;
}

export const DiscussionList: React.FC<DiscussionListProps> = ({
  discussions,
  roundtablesAppApi,
  onDiscussionClick,
  selectedDiscussion,
  onDiscardEdit,
  editValue,
  onEditInputChange,
  onEditSubmit,
  isEditMode,
  onSubmitComment,
  onSelectComment,
  selectedComment,
  members,
  onSelectMember,
}) => {
  if (!members || members.length === 0) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="discussion-list">
      {discussions.map(discussion =>
        selectedDiscussion &&
        selectedDiscussion.uid === discussion.uid &&
        isEditMode ? (
          <DiscussionInput
            key={discussion.uid}
            author={roundtablesAppApi.authUser}
            roundtablesAppApi={roundtablesAppApi}
            onDiscardDiscussion={onDiscardEdit}
            onSubmit={onEditSubmit}
            onInputChange={onEditInputChange}
            inputName="editDiscussion"
            value={
              editValue.editDiscussion
                ? editValue.editDiscussion
                : selectedDiscussion.value
            }
          />
        ) : (
          <DiscussionItem
            discussion={discussion}
            isAuthor={discussion.userId === roundtablesAppApi.authUser.uid}
            isSelected={
              selectedDiscussion && selectedDiscussion.uid === discussion.uid
            }
            key={discussion.uid}
            members={members}
            onDiscussionClick={onDiscussionClick}
            onSelectComment={onSelectComment}
            onSelectMember={onSelectMember}
            onSubmitComment={onSubmitComment}
            selectedComment={selectedComment}
          />
        ),
      )}
    </div>
  );
};
