import React, { useState } from "react";
import { compose } from "recompose";

import { withAuthorization } from "../Auth";

import "./index.scss";
import { Header } from "components/Molecules/Header";
import UserCard from "components/Molecules/UserCard";
import { MenuIcon } from "constants/icons";
import RoundtableList from "containers/DiscoverPage/RoundtableList/";
import { Roundtable, User } from "types";
import RoundtablesAppApi from "api/roundtablesAppApi";
import { AccountMenu } from "./AccountMenu";

interface AccountPageProps {
  roundtables: Roundtable[];
  user: User;
  roundtablesAppApi: RoundtablesAppApi;
}

const AccountPage: React.FC<AccountPageProps> = ({
  user,
  roundtables,
  roundtablesAppApi,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (!user) {
    return <div>...</div>;
  }

  return (
    <div className="account-container">
      <div className="account-page page">
        {menuOpen ? <AccountMenu toggleMenu={toggleMenu} /> : null}
        <Header title="Profile">
          <button className="link" onClick={() => toggleMenu()}>
            <MenuIcon />
          </button>
        </Header>
        <UserCard user={user} wide />
        <section className="my-roundtables">
          <h2 className="subtitle">My Roundtables</h2>
          <RoundtableList
            roundtables={roundtables}
            roundtablesAppApi={roundtablesAppApi}
          />
        </section>
      </div>
    </div>
  );
};

const condition = authUser => authUser?.approved;

export default compose<AccountPageProps, any>(withAuthorization(condition))(
  AccountPage,
);
