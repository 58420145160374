import React from "react";

import "./index.scss";

interface InputProps {
  name: string;
  value: string;
  onChange: (event: any) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "number" | "url";
  children?: any;
}

export const Input: React.FC<InputProps> = ({
  children,
  className = "",
  disabled,
  name,
  value,
  placeholder,
  onChange,
  type = "text",
}: InputProps) => {
  return (
    <input
      disabled={disabled}
      className={`input ${className}`}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
    >
      {children}
    </input>
  );
};
