import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { FirebaseContext } from "./fb/context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as ROUTES from "./constants/routes";
import Account from "containers/AccountPage/";
import Admin from "containers/AdminPage/";
import Create from "containers/CreateRoundtablePage/";
import DiscoverPage from "containers/DiscoverPage";
import NewHome from "containers/NewHome";
import ForgetPW from "containers/AuthPage/password-forget";
import AppLayout from "containers/AppLayout";
import RequestsPage from "containers/Requests";

import AppContext from "context/app-context";
import RoundtablesAppApi, { useRoundtablesAppApi } from "api/roundtablesAppApi";
import { EditProfilePage } from "containers/EditProfilePage";
import { useMyRoundtables, fbAuth } from "fb";
import Roundtable from "containers/Roundtable";

toast.configure();

const App: React.FC<{}> = () => {
  const { authApi } = useContext(FirebaseContext);
  const roundtablesAppApi = useRoundtablesAppApi(authApi);
  const { pathname } = useLocation();

  return (
    <Switch>
      <AppContext.Provider value={roundtablesAppApi}>
        <Route
          exact
          path={ROUTES.WELCOME}
          render={() => <NewHome user={roundtablesAppApi?.authUser} />}
        />
        {pathname !== ROUTES.WELCOME && pathname !== "/new-home" ? (
          <Home roundtablesAppApi={roundtablesAppApi} />
        ) : null}
      </AppContext.Provider>
    </Switch>
  );
};

const Home: React.FC<{
  roundtablesAppApi: RoundtablesAppApi;
}> = ({ roundtablesAppApi }) => {
  const { joinedRoundtables } = useMyRoundtables(fbAuth);

  return (
    <AppLayout
      user={roundtablesAppApi.authUser}
      roundtables={joinedRoundtables}
    >
      <Route exact path={ROUTES.PASSWORD_FORGET} component={ForgetPW} />
      <Route exact path={ROUTES.HOME} component={DiscoverPage} />
      <Route exact path={ROUTES.CREATE} component={Create} />
      <Route
        exact
        path={ROUTES.REQUESTS}
        render={() => <RequestsPage joinedRoundtables={joinedRoundtables} />}
      />
      <Route
        exact
        path={ROUTES.ACCOUNT}
        render={() => (
          <Account
            roundtablesAppApi={roundtablesAppApi}
            roundtables={joinedRoundtables}
            user={roundtablesAppApi?.authUser}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.EDIT_PROFILE}
        render={() => <EditProfilePage user={roundtablesAppApi?.authUser} />}
      />
      <Route
        path={`${ROUTES.ROOM}/:roundtableId`}
        render={({ match }) => (
          <Roundtable
            roundtables={joinedRoundtables}
            roundtableId={match.params.roundtableId as string}
            roundtablesAppApi={roundtablesAppApi}
          />
        )}
      />
      <Route path={ROUTES.ADMIN} component={Admin} />
    </AppLayout>
  );
};

export default App;
