import React, { useMemo } from "react";
import { Roundtable } from "../../../types";
import * as ROUTES from "constants/routes";
import RoundtablesAppApi from "api/roundtablesAppApi";
import { TagIcon, PeopleIcon } from "constants/icons";

import "./index.scss";
import { RoundtableButton } from "./RoundtableButton";
import { useHistory } from "react-router-dom";

interface RoundtableItemProps {
  roundtable: Roundtable;
  roundtablesAppApi?: RoundtablesAppApi;
  onJoinRequest?: (roundtable: Roundtable) => void;
  onCancelJoinRequest?: (roundtable: Roundtable) => void;
}

export const RoundtableItem: React.FC<RoundtableItemProps> = ({
  roundtable,
  roundtablesAppApi,
  onJoinRequest,
  onCancelJoinRequest,
}) => {
  const history = useHistory();

  const isMember = useMemo(
    () => roundtablesAppApi.isMemberOfRoundtable(roundtable),
    [roundtable, roundtablesAppApi],
  );

  const isPendingApproval = useMemo(
    () => roundtablesAppApi.isMemberPendingApproval(roundtable),
    [roundtable, roundtablesAppApi],
  );

  const handleClick = () => {
    if (isMember) {
      return history.push(`${ROUTES.ROOM}/${roundtable.uid}`);
    } else if (isPendingApproval) {
      return onCancelJoinRequest(roundtable);
    } else {
      return onJoinRequest(roundtable);
    }
  };

  return (
    <li className="roundtable-card" onClick={handleClick}>
      <section className="content">
        <h2>
          <span>{roundtable.sign}</span> {roundtable.name}
        </h2>
        <p>{roundtable.description}</p>
      </section>
      <footer>
        <div className="info">
          <div>
            <TagIcon />
            <span>{roundtable.tag}</span>
          </div>
          <div>
            <PeopleIcon />
            {roundtable.limit === 0 ? (
              <span>{roundtable.members.length || 0}</span>
            ) : (
              <span>{roundtable.members.length || 0}/20</span>
            )}
          </div>
        </div>
        <div>
          {roundtablesAppApi.authUser ? (
            <RoundtableButton
              isMember={isMember}
              isPendingApproval={isPendingApproval}
            />
          ) : null}
        </div>
      </footer>
    </li>
  );
};
