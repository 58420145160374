import React from "react";

import DefaultAvatar from "static/default-avatar.png";

import "./avatar.scss";

interface AvatarProps {
  image?: string;
  onClick?;
}

export const Avatar: React.FC<AvatarProps> = ({ image, onClick }) => {
  return (
    <div className="avatar" onClick={onClick}>
      <img src={image ? image : DefaultAvatar} alt="avatar" />
    </div>
  );
};
