import { firestore } from "firebase/app";
import { fbDb } from "fb";

export const getTime = () => firestore.Timestamp.fromDate(new Date());

export const addUserToRoundtable = (roundtable, uid) =>
  fbDb
    .collection("roundtables")
    .doc(roundtable)
    .update({
      members: firestore.FieldValue.arrayUnion(uid),
    });
