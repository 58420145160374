import React from "react";
import { Discussion, Roundtable } from "types";
import { Header } from "components/Molecules/Header";
import { DeleteIcon, EditIcon, MenuIcon } from "constants/icons";

interface RoundtableHeaderProps {
  isEditMode: boolean;
  selectedDiscussion: Discussion;
  roundtable: Roundtable;
  selectedComment: { discussionId: string; commentId: string };
  onDeleteDiscussion: (roundtableId: string, discussionId: string) => void;
  onEditDiscussion: (value: string) => void;
  onDeleteComment: () => void;
  onToggleMenu: () => void;
}

const RoundtableHeader: React.FC<RoundtableHeaderProps> = ({
  isEditMode,
  selectedDiscussion,
  onEditDiscussion,
  roundtable,
  onDeleteDiscussion,
  selectedComment,
  onDeleteComment,
  onToggleMenu,
}) => {
  const setHeader = () => {
    if (selectedDiscussion && !isEditMode) {
      return (
        <React.Fragment>
          <span
            className="link"
            onClick={() =>
              onDeleteDiscussion(roundtable.uid, selectedDiscussion.uid)
            }
          >
            <DeleteIcon />
          </span>
          <span
            className="link"
            onClick={() => onEditDiscussion(selectedDiscussion.value)}
          >
            <EditIcon />
          </span>
        </React.Fragment>
      );
    } else if (selectedComment) {
      return (
        <span className="link" onClick={() => onDeleteComment()}>
          <DeleteIcon />
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Header title={roundtable.name}>
        {setHeader()}{" "}
        <span className="link toggle-menu" onClick={onToggleMenu}>
          <MenuIcon />
        </span>
      </Header>
    </div>
  );
};

export default RoundtableHeader;
