import React from "react";
import AccountForm from "./AccountForm";
import { Header } from "components/Molecules/Header";
import { useHistory, useLocation } from "react-router-dom";
import { ACCOUNT } from "constants/routes";

import "./index.scss";
import { User } from "types";

export const EditProfilePage: React.FC<{ user: User }> = ({ user }) => {
  let history = useHistory();
  let location = useLocation();

  const goBack = () => {
    history.push(ACCOUNT);
  };

  if (!user) {
    return <div>...</div>;
  }

  return (
    <div className="edit-profile">
      <Header title="Edit Profile" onClick={goBack} x />
      <AccountForm
        user={user}
        pendingRoundtable={(location.state as any)?.pendingRoundtable}
        history={history}
      />
    </div>
  );
};
