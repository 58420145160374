import React, { useState } from "react";

const useForm = (callback, initialState) => {
  const [inputs, setInputs] = useState(initialState || {});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    callback();
  };

  const handleInputChange = e => {
    e.persist();
    setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
  };

  return { handleInputChange, handleSubmit, inputs, setInputs };
};
export default useForm;
