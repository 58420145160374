import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Roundtable, User } from "types";
import { ACCOUNT, HOME, REQUESTS, ROOM } from "constants/routes";
import {
  SearchIcon,
  CollapseMenuIcon,
  NotificationsIcons,
} from "constants/icons";

import * as ROUTES from "constants/routes";
import { RoundtableMenuBlock } from "./RoundtableMenuBlock";
import { Avatar } from "../../components/Atoms/Avatar";

import "./index.scss";

interface SidebarProps {
  roundtables: Roundtable[];
  user: User;
}

const Sidebar: React.FC<SidebarProps> = ({ roundtables, user }) => {
  const [selected, setSelected] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [items, setItems] = useState([]);

  let history = useHistory();

  useEffect(() => {
    const path = history.location.pathname.split("/");
    // TODO: Flaky cause it relies on specific url structure
    if (path[1] === ROUTES.ROOM.split("/")[1]) {
      setSelected(path[2]);
    } else {
      setSelected(path[1]);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    let joinedRoundtables: Roundtable[] = roundtables;
    const index = joinedRoundtables.findIndex(
      roundtable => roundtable.uid === "town-hall",
    );
    if (index > -1) {
      const townhall: Roundtable = joinedRoundtables.splice(index, 1)[0];
      joinedRoundtables.unshift(townhall);
    }
    setItems(joinedRoundtables);
  }, [roundtables]);

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <ul>
        <li className={selected === "home" ? "selected" : ""}>
          <NavLink to={HOME}>
            <p>Discover</p>
            <RoundtableMenuBlock icon={SearchIcon} />
          </NavLink>
        </li>
        {items.map((roundtable: Roundtable) => {
          return (
            <li
              key={roundtable.uid}
              className={selected === roundtable.uid ? "selected" : ""}
            >
              <NavLink to={`${ROOM}/${roundtable.uid}`}>
                <p>{roundtable.name}</p>
                <RoundtableMenuBlock className="group" name={roundtable.sign} />
              </NavLink>
            </li>
          );
        })}
      </ul>

      <footer>
        <ul>
          <li
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <button className="link">
              <p>Hide Menu</p>
              <RoundtableMenuBlock icon={CollapseMenuIcon} />
            </button>
          </li>
          <li className={selected === "requests" ? "selected" : ""}>
            <NavLink to={REQUESTS}>
              <p>Requests</p>
              <RoundtableMenuBlock icon={NotificationsIcons} />
            </NavLink>
          </li>
          <li className={selected === "profile" ? "selected" : ""}>
            <NavLink to={ACCOUNT}>
              <p>Profile</p>
              <div className="roundtable-menu-block selected-avatar">
                <Avatar image={user?.photoURL} />
              </div>
            </NavLink>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Sidebar;
