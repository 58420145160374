import React, { useState } from "react"
import { Link } from "react-router-dom"
import * as ROUTES from "../../constants/routes"
import { withFirebase } from "../../fb/context"

const PasswordForgetPage = () => (
  <div>
    <h1>Reset Password</h1>
    <PasswordForgetForm />
  </div>
);

const PasswordForgetFormBase = ({ authApi }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();
    authApi
      .doResetPassword(email)
      .then(() => {
        setEmail("");
        setError(null)
      })
      .catch(err => setError(err))
  };

  const onChange = event => {
    setEmail(event.target.value)
  };

  const isInvalid = email === "";
  return (
    <form onSubmit={onSubmit}>
      <input
        type="text"
        name="email"
        onChange={onChange}
        placeholder="Emaill address"
        value={email}
      />
      <button disabled={isInvalid}>Reset Password</button>
      {error && <p>{error.message}</p>}
    </form>
  )
};

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink }
