import React from "react";
import { Header } from "components/Molecules/Header";
import { XIcon, DeleteIcon } from "constants/icons";
import { Button } from "components/Atoms/Button";
import { Avatar } from "components/Atoms/Avatar";
import { Roundtable, User } from "../../../types/index";
import { Link, useRouteMatch, useHistory } from "react-router-dom";

import * as ROUTES from "constants/routes";

import "./index.scss";
import RoundtablesAppApi from "api/roundtablesAppApi";

interface ChatMenuInlineProps {
  roundtable: Roundtable;
  members: User[];
  isAdmin: boolean;
  roundtablesAppApi: RoundtablesAppApi;
  className?: string;

  onToggle: () => void;
  handleKick?: (roundtableId: string, memberId: string) => void;
  onSelectMember: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    member: User,
  ) => void;
}

const ChatMenuInline: React.FC<ChatMenuInlineProps> = ({
  onToggle,
  roundtable,
  members,
  handleKick,
  isAdmin,
  className,
  roundtablesAppApi,
  onSelectMember,
}) => {
  let { url } = useRouteMatch();
  const isMemberAdmin = (id: string) => roundtable.admins.includes(id);
  let history = useHistory();
  const handleLeave = () => {
    roundtablesAppApi.leaveRoundtable(roundtable);

    //TODO: RoundtableRoute should handle redirecting you to Discover
    history.push(ROUTES.HOME);
  };

  return (
    <div className={`chat-menu ${className ? className : null}`}>
      <Header title="Details">
        <button className="link toggle-menu" onClick={() => onToggle()}>
          <XIcon />
        </button>
      </Header>

      <section className="content">
        <p>{roundtable.description}</p>
        <div className="actions">
          {isAdmin && (
            <Link to={`${url}/edit`}>
              <Button primary>Edit Details</Button>
            </Link>
          )}
          <Button onClick={handleLeave}>Leave Roundtable</Button>
        </div>
        <ul className="users">
          {members?.map(member => (
            <li key={member.uid} onClick={e => onSelectMember(e, member)}>
              <Avatar image={member?.photoURL} />
              <span>{member.username}</span>

              {isMemberAdmin(member.uid) && (
                <span className="admin">Facilitator</span>
              )}

              {!isMemberAdmin(member.uid) && isAdmin && (
                <button
                  className="link"
                  onClick={() => handleKick(roundtable.uid, member.uid)}
                >
                  <DeleteIcon />
                </button>
              )}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ChatMenuInline;
