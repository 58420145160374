import { useState, useEffect } from "react";
import { Roundtable } from "../types";
import { fbDb } from "./";

interface MyRoundtablesType {
  joinedRoundtables: Roundtable[];
  adminRoundtables: Roundtable[];
}

export const useMyRoundtables = fbAuth => {
  const [roundtableGroups, updateRoundtableGroups] = useState<
    MyRoundtablesType
  >({
    joinedRoundtables: [],
    adminRoundtables: [],
  });

  const currentUid = fbAuth.currentUser && fbAuth.currentUser.uid;

  useEffect(() => {
    if (!currentUid) {
      return;
    }
    (async () => {
      await fbDb.collection("roundtables").onSnapshot(snapshot => {
        const allRoundtables = [];

        snapshot.forEach(doc =>
          allRoundtables.push(convertSnapshotToData(doc)),
        );
        const joinedRoundtables = [];
        const adminRoundtables = [];

        for (const roundtable of allRoundtables) {
          const userMember = roundtable.members.find(uid => {
            return uid === currentUid;
          });
          if (userMember) {
            joinedRoundtables.push(roundtable);
          }
          if (roundtable.admins.length > 0) {
            const userAdmin = roundtable.admins.find(uid => uid === currentUid);
            if (userAdmin) {
              adminRoundtables.push(roundtable);
            }
          }
        }
        updateRoundtableGroups({
          joinedRoundtables,
          adminRoundtables,
        });
      });
    })();
  }, [currentUid]);
  return roundtableGroups;
};

export const useSuggestedRoundtables = fbAuth => {
  const [suggestedRoundtables, updateSuggestedRoundtables] = useState([]);
  const currentUid = fbAuth.currentUser && fbAuth.currentUser.uid;
  useEffect(() => {
    if (!currentUid) {
      return;
    }
    (async () => {
      // TODO: We should not remove components with business logic - This should not be here
      // TODO This components should not be expose to the DB layer
      await fbDb.collection("roundtables").onSnapshot(snapshot => {
        const allRoundtables = [];
        snapshot.forEach(doc =>
          allRoundtables.push(convertSnapshotToData(doc)),
        );
        const newSuggestedRoundtables = [];
        for (const roundtable of allRoundtables) {
          const userMember = roundtable.members.find(
            ({ uid }) => uid === currentUid,
          );
          if (!userMember) {
            newSuggestedRoundtables.push(roundtable);
          }
        }
        updateSuggestedRoundtables(newSuggestedRoundtables);
      });
    })();
  }, [currentUid]);
  return suggestedRoundtables;
};

const convertSnapshotToData = doc => ({ uid: doc.id, ...doc.data() });
