import { fbAuth, fbDb } from "fb";
import { addUserToRoundtable, getTime } from "fb/utils";
// import * as userActions from "state/users/actions";

import firebase from "firebase/app";
// import { useDispatch } from "react-redux";

export class AuthApi {
  public doCreateUserWithEmailAndPassword = (email, password) =>
    fbAuth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        addUserToRoundtable("town-hall", res.user.uid);
        return res;
      })
      .catch(error => {
        throw new Error(error.message);
      });

  public doSignInWithEmailAndPassword = (email, password) =>
    fbAuth.signInWithEmailAndPassword(email, password);

  public doSignOut = () => fbAuth.signOut();

  public doResetPassword = email => fbAuth.sendPasswordResetEmail(email);
  // TODO: Also remove from the public database, and all joined roundtables
  public doDeleteSelf = () => {
    fbDb
      .collection("users")
      .doc(fbAuth.currentUser.uid)
      .delete();
    fbAuth.currentUser.delete();
  };

  public doUpdatePassword = password =>
    fbAuth.currentUser.updatePassword(password);

  public doSignInWithGoogle = () =>
    fbAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());

  public doGetRedirectResult = () =>
    fbAuth
      .getRedirectResult()
      .then(res => {
        if (res.additionalUserInfo?.isNewUser) {
          this.setUser(res);
          fbDb
            .collection("roundtables")
            .doc("town-hall")
            .update({
              members: firebase.firestore.FieldValue.arrayUnion(res.user.uid),
            })
            .then(() => console.log("document updated"));
        }
        return res;
      })
      .catch(error => {
        throw new Error(error);
      });

  public doSignInWithFacebook = () =>
    fbAuth.signInWithRedirect(new firebase.auth.FacebookAuthProvider());

  public doSendEmailVerification = () =>
    fbAuth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  /**
   * Merge Auth and Db user
   * We need to pass fbAuth here otherwise it can't find it
   */
  public onAuthListener = (next, fallback) => {
    return fbAuth.onAuthStateChanged(authUser => {
      if (authUser) {
        fbDb
          .collection("users")
          .doc(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            let nextAuthUser = null;
            if (dbUser) {
              if (!dbUser.roles) {
                dbUser.roles = [];
              }

              // merge auth and db user
              nextAuthUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
            }

            next(nextAuthUser || authUser);
          });
      } else {
        fallback();
      }
    });
  };

  /**
   * Set a given social auth user in the users table
   * @param {firebase.auth.UserCredential} socialAuthUser - auth user (gmail, facebook)
   */
  public setUser(socialAuthUser: firebase.auth.UserCredential) {
    return fbDb
      .collection("users")
      .doc(socialAuthUser.user.uid)
      .set({
        email: socialAuthUser.user.email,
        username: socialAuthUser.user.displayName,
        photoURL: socialAuthUser.user.photoURL,
        created: getTime(),
        approved: false,
        roles: {},
      });
  }
}

export const authApi = new AuthApi();
