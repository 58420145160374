import React, { useContext } from "react";
import { Button } from "../../components/Atoms/Button";
import { Input } from "../../components/Atoms/Input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { User, Roundtable } from "types";
import * as userActions from "state/users/actions";
import { History } from "history";
import useForm from "utils/FormHook";
import { HOME } from "constants/routes";
import AppContext from "context/app-context";

interface AccountFormProps {
  user: User;
  pendingRoundtable: Roundtable;
  history: History<{}>;
}

const AccountForm: React.FC<AccountFormProps> = ({
  user,
  pendingRoundtable,
  history,
}) => {
  const INITIAL_STATE = {
    bio: user.bio ? user.bio : "",
    title: user.title ? user.title : "",
    username: user.username ? user.username : "",
    location: user.location ? user.location : "",
    startDate: user.startDate ? user.startDate : "",
    intention: user.intention ? user.intention : "",
  };
  const roundtablesAppApi = useContext(AppContext);
  const dispatch = useDispatch();

  const updateRoundtable = () => {
    dispatch(userActions.updateUser(user.uid, inputs));
    roundtablesAppApi.authUser = Object.assign(
      roundtablesAppApi.authUser,
      inputs,
    );
    toast("Profile updated, good job 💪");
    if (
      pendingRoundtable &&
      inputs.username &&
      inputs.bio &&
      inputs.location &&
      inputs.title
    ) {
      history.push({ pathname: HOME, state: { joinPod: pendingRoundtable } });
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    updateRoundtable,
    INITIAL_STATE,
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="fields">
        <label>
          A short bio <span className="required">*</span>
        </label>
        <Input
          value={inputs.bio}
          name="bio"
          onChange={handleInputChange}
          placeholder="What should people know"
        />

        <label>
          Choose a username <span className="required">*</span>
        </label>
        <Input
          value={inputs.username}
          name="username"
          onChange={handleInputChange}
          placeholder="How should people call you"
        />
        <label>
          Where are you from? <span className="required">*</span>
        </label>
        <Input
          value={inputs.location}
          name="location"
          onChange={handleInputChange}
          placeholder="Originally, or currently"
        />
        <label>
          What's your current title? <span className="required">*</span>
        </label>
        <Input
          value={inputs.title}
          name="title"
          onChange={handleInputChange}
          placeholder="Supreme Design Overlord"
        />
        <label>When did you start your career?</label>
        <Input
          value={inputs.startDate}
          name="startDate"
          onChange={handleInputChange}
          placeholder="2020"
          type="number"
        />
        <label>What's your intention for the app?</label>
        <Input
          value={inputs.intention}
          name="intention"
          onChange={handleInputChange}
          placeholder="e.g find a job"
        />
        <p>
          <span className="required">*</span> Required to join a new Roundtable,
          and will be visible to other members
        </p>
      </div>
      <footer>
        <Button primary type="submit">
          Save Changes
        </Button>
      </footer>
    </form>
  );
};

export default AccountForm;
