import React, { useContext, useMemo, useState, useEffect } from "react";
import { Header } from "components/Molecules/Header";

import "./index.scss";
import AppContext from "context/app-context";
import { compose } from "recompose";
import { withAuthorization } from "containers/Auth";
// import { Avatar } from "components/Atoms/Avatar";
import { Button } from "components/Atoms/Button";
import { Request, Roundtable } from "types";
import moment from "moment";
import RoundtablesAppApi from "api/roundtablesAppApi";
import usersApi from "api/usersApi";
import { Avatar } from "components/Atoms/Avatar";

interface RequestsPageProps {
  joinedRoundtables: Roundtable[];
}

const getCalendarDate = seconds => {
  let t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(seconds);
  return moment(t).calendar();
};

const RequestsPage: React.FC<RequestsPageProps> = ({ joinedRoundtables }) => {
  const roundtablesAppApi = useContext(AppContext);

  const requests = useMemo(
    () => roundtablesAppApi.getRequests(joinedRoundtables),
    [roundtablesAppApi, joinedRoundtables],
  );

  if (!roundtablesAppApi.authUser) {
    return <div>You must be logged in</div>;
  }

  if (requests.length === 0)
    return (
      <div className="no-requests">
        <Header title="Requests" />
        <p>
          This is where you'll see requests from members to join your Roundtable
        </p>
        <p>There are currently no pending requests</p>
      </div>
    );

  return (
    <div>
      <Header title="Requests" />
      <ul className="requests">
        {requests.map(({ request, roundtable }) => {
          return (
            <RequestItem
              request={request}
              roundtable={roundtable}
              roundtablesAppApi={roundtablesAppApi}
              key={request.userUid + request.roundtableUid}
            />
          );
        })}
      </ul>
    </div>
  );
};

const RequestItem = ({
  request,
  roundtable,
  roundtablesAppApi,
}: {
  request: Request;
  roundtable: Roundtable;
  roundtablesAppApi: RoundtablesAppApi;
}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    usersApi.getUser(request.userUid).then(setUser);
  }, [request.userUid]);

  return (
    <li className="request">
      <section className="request-header">
        {user ? <Avatar image={user.photoURL} /> : null}
        <span className="time">{getCalendarDate(request.created.seconds)}</span>
      </section>
      <div className="content">
        <p>
          {user
            ? `${user.username}, a ${user.title} from ${user.location}, has requested to join your roundtable `
            : `loading user info`}
          <i>{roundtable.name}</i>
        </p>
        <div className="actions">
          <Button
            onClick={() =>
              roundtablesAppApi.rejectJoinRequest(roundtable, request.userUid)
            }
          >
            Reject
          </Button>
          <Button
            primary
            onClick={() =>
              roundtablesAppApi.approveJoinRequest(roundtable, request.userUid)
            }
          >
            Approve
          </Button>
        </div>
      </div>
    </li>
  );
};

const condition = authUser => authUser?.approved;

export default compose<RequestsPageProps, any>(withAuthorization(condition))(
  RequestsPage,
);
