import React from "react";
import { AuthApi, authApi } from "api/authApi";
import { fbApp, fbAuth, fbDb, fbAnalytics } from "fb/index";
import * as firebase from "firebase/app";

type contextProps = {
  fbApp: firebase.app.App;
  fbAuth: firebase.auth.Auth;
  fbDb: firebase.firestore.Firestore;
  authApi: AuthApi;
  fbAnalytics: firebase.analytics.Analytics;
};

const context = React.createContext<Partial<contextProps>>({
  fbApp,
  fbAuth,
  fbDb,
  authApi,
  fbAnalytics,
});

export const withFirebase = Component => props => (
  <context.Consumer>
    {({ fbApp, fbAuth, fbDb, authApi, fbAnalytics }) => {
      return (
        <Component
          {...props}
          fbApp={fbApp}
          fbAuth={fbAuth}
          fbDb={fbDb}
          authApi={authApi}
          fbAnalytics={fbAnalytics}
        />
      );
    }}
  </context.Consumer>
);

export default context;
