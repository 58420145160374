import React from "react";

import "./index.scss";

const PageModal: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={`overlay ${className ? className : null}`}>
    <div className="overlay-container">{children}</div>
  </div>
);

export default PageModal;
