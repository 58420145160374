import React, { useMemo, useState, useEffect, useContext } from "react";
import "./index.scss";
import { DiscussionList } from "../DiscussionList";
import { getTime } from "fb/utils";
import { PlusIcon } from "constants/icons";
import { Roundtable, Discussion, Comment, User } from "types";
import RoundtableMenu from "../RoundtableMenu";
import DiscussionInput from "../DiscussionInput";
import RoundtableHeader from "../RoundtableHeader/";
import RoundtablesAppApi from "api/roundtablesAppApi";
import uid from "uid";
import useForm from "utils/FormHook";
import usersApi from "api/usersApi";
import MemberView from "../MemberView";
import { FirebaseContext } from "fb/context";

interface RoundtableRoomProps {
  roundtable: Roundtable;
  roundtablesAppApi: RoundtablesAppApi;
}

const RoundtableRoom: React.FC<RoundtableRoomProps> = ({
  roundtable,
  roundtablesAppApi,
}) => {
  const [discussions, setDiscussions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isInputShown, setIsInputShown] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const { fbAnalytics } = useContext(FirebaseContext);

  useEffect(() => {
    if (roundtable?.members)
      usersApi.getUsers(roundtable.members).then(setMembers);
  }, [roundtable]);

  const user = roundtablesAppApi.authUser;

  const isAdmin = useMemo(() => roundtable?.admins.includes(user.uid), [
    user,
    roundtable,
  ]);

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleSelectMember = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    member: User,
  ) => {
    e.stopPropagation();
    setSelectedMember(member);
  };

  const handleCloseSelectedMember = () => {
    setSelectedMember(null);
  };

  /**** Get Discussions ****/
  const handleDiscussionsUpdate = (
    snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    let discussionList = [];
    snap.forEach(doc => discussionList.push({ ...doc.data(), uid: doc.id }));
    setDiscussions(discussionList);
  };

  useEffect(() => {
    const unsubscribe = roundtablesAppApi.registerToDiscussions(
      roundtable.uid,
      handleDiscussionsUpdate,
    );
    return () => unsubscribe();
  }, [roundtable.uid, roundtablesAppApi]);

  /**** New Discussion Form ****/
  const handleOpenNewDiscussion = () => {
    setIsInputShown(true);
    setSelectedDiscussion(null);
    setSelectedComment(null);
    setIsEditMode(false);
  };
  const INITIAL_STATE = {
    newDiscussion: "",
  };

  const handleCreateDiscussion = () => {
    roundtablesAppApi.createDiscussion(roundtable.uid, inputs.newDiscussion);
    setIsInputShown(false);
    fbAnalytics.logEvent("new_discussion", {
      value: inputs.newDiscussion,
      roundtable: roundtable.uid,
      user: user.uid,
    });
    setInputs({ newDiscussion: "" });
  };

  const { inputs, handleInputChange, handleSubmit, setInputs } = useForm(
    handleCreateDiscussion,
    INITIAL_STATE,
  );
  const handleDiscardNewDiscussion = () => setIsInputShown(false);

  /**** Edit Discussion ****/
  const INITIAL_STATE_EDIT_DISCUSSION = {
    editDiscussion: "",
  };

  const handleSaveEdit = () => {
    roundtablesAppApi.updateDiscussion(
      roundtable.uid,
      selectedDiscussion.uid,
      inputsEditDiscussion.editDiscussion,
    );
    setIsEditMode(false);
    setSelectedDiscussion(null);
    setInputsEditDiscussion("");
  };

  const {
    inputs: inputsEditDiscussion,
    handleInputChange: handleInputChangeEditDiscussion,
    handleSubmit: handleSubmitEditDiscussion,
    setInputs: setInputsEditDiscussion,
  } = useForm(handleSaveEdit, INITIAL_STATE_EDIT_DISCUSSION);

  const handleDeleteDiscussion = (
    roundtableId: string,
    discussionId: string,
  ) => {
    setSelectedDiscussion(null);
    roundtablesAppApi.deleteDiscussion(roundtableId, discussionId);
  };

  const handleEditDiscussion = () => {
    setIsEditMode(true);
    setIsInputShown(false);
  };

  const handleDiscardEdit = () => {
    setIsEditMode(false);
  };

  /**** Comments ****/
  const handleSubmitComment = (discussionId: string, value: string) => {
    if (value) {
      const newComment: Comment = {
        value,
        created: getTime(),
        userId: user.uid,
        uid: uid(),
      };
      roundtablesAppApi.createComment(roundtable.uid, discussionId, newComment);
      fbAnalytics.logEvent("new_discussion", {
        value: newComment.value,
        roundtable: roundtable.uid,
        user: user.uid,
      });
    }
  };

  const handleDeleteComment = () => {
    roundtablesAppApi.deleteComment(
      roundtable.uid,
      selectedComment.discussion,
      selectedComment.commentId,
    );
  };

  const handleSelectComment = (discussion: Discussion, comment: Comment) => {
    setSelectedDiscussion(null);
    if (
      selectedComment &&
      discussion.uid === selectedComment.discussion.uid &&
      comment.uid === selectedComment.commentId
    ) {
      setSelectedComment(null);
    } else if (comment.userId === user.uid) {
      setSelectedComment({ discussion, commentId: comment.uid });
    }
  };

  /**** Select Discussion ****/
  const handleSelectDiscussion = (discussion: Discussion) => {
    setSelectedComment(null);
    if (selectedDiscussion && selectedDiscussion.uid === discussion.uid) {
      setSelectedDiscussion(null);
    } else if (discussion.userId === user.uid) {
      setSelectedDiscussion(discussion);
      setIsEditMode(false);
    }
  };

  return (
    <div className="roundtable-room ">
      <div
        className={`feed page ${
          isMenuVisible || selectedMember ? "hidden" : null
        }`}
      >
        <button className="add-discussion" onClick={handleOpenNewDiscussion}>
          <PlusIcon />
        </button>
        <RoundtableHeader
          isEditMode={isEditMode}
          onDeleteDiscussion={handleDeleteDiscussion}
          onEditDiscussion={handleEditDiscussion}
          roundtable={roundtable}
          selectedDiscussion={selectedDiscussion}
          selectedComment={selectedComment}
          onDeleteComment={handleDeleteComment}
          onToggleMenu={handleToggleMenu}
        />
        <div className="container">
          {isInputShown ? (
            <DiscussionInput
              author={user}
              roundtablesAppApi={roundtablesAppApi}
              onDiscardDiscussion={handleDiscardNewDiscussion}
              onSubmit={handleSubmit}
              onInputChange={handleInputChange}
              value={inputs.newDiscussion}
            />
          ) : null}
          <DiscussionList
            discussions={discussions}
            editValue={inputsEditDiscussion}
            isEditMode={isEditMode}
            members={members}
            onDiscardEdit={handleDiscardEdit}
            onDiscussionClick={handleSelectDiscussion}
            onEditInputChange={handleInputChangeEditDiscussion}
            onEditSubmit={handleSubmitEditDiscussion}
            onSelectComment={handleSelectComment}
            onSelectMember={handleSelectMember}
            onSubmitComment={handleSubmitComment}
            roundtablesAppApi={roundtablesAppApi}
            selectedComment={selectedComment}
            selectedDiscussion={selectedDiscussion}
          />
        </div>
      </div>
      {selectedMember ? (
        <MemberView
          members={members}
          member={selectedMember}
          discussions={discussions}
          onCloseSelectedMember={handleCloseSelectedMember}
          onSelectMember={handleSelectMember}
        />
      ) : (
        <RoundtableMenu
          className="menu"
          members={members}
          onToggle={handleToggleMenu}
          roundtable={roundtable}
          // handleKick={handleKick}
          isAdmin={isAdmin}
          roundtablesAppApi={roundtablesAppApi}
          onSelectMember={handleSelectMember}
        />
      )}
    </div>
  );
};

export default RoundtableRoom;
