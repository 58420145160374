import React from "react";
import { Roundtable } from "../../../types";
import RoundtablesAppApi from "api/roundtablesAppApi";
import { RoundtableItem } from "../RoundtableItem";

import "./index.scss";

interface RoundtableListProps {
  roundtables: Roundtable[];
  roundtablesAppApi: RoundtablesAppApi;
  onJoinRequest?: (roundtable: Roundtable) => void;
  onCancelJoinRequest?: (roundtable: Roundtable) => void;
}

const RoundtableList: React.FC<RoundtableListProps> = ({
  roundtables,
  roundtablesAppApi,
  onJoinRequest,
  onCancelJoinRequest,
  children,
}) => {
  return (
    <ul className="roundtable-list">
      {roundtables.map(roundtable => (
        <RoundtableItem
          key={roundtable.uid}
          roundtable={roundtable}
          roundtablesAppApi={roundtablesAppApi}
          onJoinRequest={onJoinRequest}
          onCancelJoinRequest={onCancelJoinRequest}
        />
      ))}
      {children ? children : null}
    </ul>
  );
};

export default RoundtableList;
