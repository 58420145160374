export const WELCOME = "/";
export const SIGN_UP = `${WELCOME}/signup`;
export const SIGN_IN = `${WELCOME}/signin`;
export const SIGN_IN_WELCOME = "/signin-welcome";
export const HOME = "/home";
export const ACCOUNT = `/profile`;
export const EDIT_PROFILE = `${ACCOUNT}/edit`;
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = `/admin`;
export const ADMIN_DETAILS = "/admin/:id";
export const CREATE = `/create`;
export const REQUESTS = `/requests`;
export const ROOM = `/r`;
