import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import useForm from "utils/FormHook";
import { Input } from "components/Atoms/Input";
import { Header } from "components/Molecules/Header";
import { Button } from "components/Atoms/Button";
import { XIcon } from "constants/icons";
import { Roundtable } from "types";
import * as chatActions from "state/chat/actions";
import * as ROUTES from "constants/routes";

import "./index.scss";

const EditRoundtablePage: React.FC<{
  roundtableId: string;
  roundtable: Roundtable;
}> = ({ roundtableId, roundtable }) => {
  // TODO: Get rid of Redux
  const dispatch = useDispatch();

  const updateRoundtable = () => {
    dispatch(chatActions.updateRoundtable(roundtableId, inputs));
  };

  const INITIAL_STATE = {
    name: roundtable.name,
    tag: roundtable.tag,
    description: roundtable.description,
  };
  const { inputs, handleInputChange, handleSubmit } = useForm(
    updateRoundtable,
    INITIAL_STATE,
  );

  return (
    <div className="edit-roundtable">
      <Header title="Edit Roundtable Details">
        <Link to={`${ROUTES.ROOM}/${roundtableId}`}>
          <XIcon />
        </Link>
      </Header>
      <p>
        Edit your Roundtable's public details to help potential members
        understand what it's about.
      </p>
      <br />
      <form onSubmit={handleSubmit}>
        <Input
          name="name"
          value={inputs.name || ""}
          onChange={handleInputChange}
          placeholder="name"
        />
        <Input
          name="tag"
          value={inputs.tag || ""}
          onChange={handleInputChange}
          placeholder="tag"
        />
        <Input
          name="description"
          value={inputs.description || ""}
          onChange={handleInputChange}
          placeholder="description"
        />
        <Button primary type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EditRoundtablePage;
