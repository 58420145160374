import { fbDb } from "../../fb/firebase";
import "firebase/firestore";
import {
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UserUpdateBody,
} from "./types";

export function getUser(userId: string) {
  return async dispatch => {
    try {
      await fbDb
        .collection("users")
        .doc(userId)
        .get()
        .then(doc => {
          dispatch({ type: GET_USER_SUCCESS, payload: doc.data() });
        });
    } catch (error) {
      dispatch({ type: GET_USER_ERROR, payload: error });
    }
  };
}

export function getUsers(userIds: string[]) {
  return async dispatch => {
    try {
      const querySnapshots = await Promise.all(
        userIds.map((userId: string) => {
          return fbDb
            .collection("users")
            .doc(userId)
            .get();
        }),
      );
      const users = querySnapshots.map(snap => {
        const userObj = snap.data();
        userObj.uid = snap.id;
        return userObj;
      });
      dispatch({ type: GET_USERS_SUCCESS, payload: users });
    } catch (error) {
      dispatch({ type: LOAD_USERS_ERROR, payload: error });
    }
  };
}

export function updateUser(userId: string, body: UserUpdateBody) {
  return async dispatch => {
    try {
      await fbDb
        .collection("users")
        .doc(userId)
        .update(body);
      dispatch({ type: UPDATE_USER_SUCCESS, payload: userId });
    } catch (error) {
      dispatch({ type: UPDATE_USER_ERROR, payload: error });
    }
  };
}

export function deleteUser(userId: string) {
  return async dispatch => {
    try {
      await fbDb
        .collection("users")
        .doc(userId)
        .delete();
      dispatch({ type: DELETE_USER_SUCCESS, payload: userId });
    } catch (error) {
      dispatch({ type: DELETE_USER_ERROR, payload: error });
    }
  };
}
