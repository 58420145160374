import React, { useState, useContext } from "react";

import { withAuthorization } from "../Auth";
import AppContext from "context/app-context";

import { fbDb } from "fb";
import { getTime } from "fb/utils";

const CreateRoundtablePage: React.FC<{}> = () => {
  const [name, setName] = useState("Coffee lovers ☕️");
  const [tag, setTag] = useState("Networking");
  const [description, setDescription] = useState(
    "A space for designers who love coffee",
  );
  const [roundtableCreated, setRoundtableCreated] = useState(false);

  const roundtablesAppApi = useContext(AppContext);

  const handleChange = e => {
    e.preventDefault();
    const val = e.target.value;
    switch (e.target.name) {
      case "name":
        setName(val);
        break;
      case "tag":
        setTag(val);
        break;
      case "description":
        setDescription(val);
        break;
    }
  };

  const handleSubmit = (e, user) => {
    e.preventDefault();

    if (name && tag && description)
      // TODO: We should not remove components with business logic - This should not be here
      // TODO This components should not be expose to the DB layer
      fbDb.collection("roundtables").add({
        name,
        tag,
        description,
        members: [user.uid],
        admins: [user.uid],
        created: getTime(),
      });
    setRoundtableCreated(true);
  };

  return (
    <div>
      <h1>Create a roundtable</h1>
      <form onSubmit={e => handleSubmit(e, roundtablesAppApi?.authUser)}>
        <input
          onChange={handleChange}
          type="text"
          name="name"
          placeholder="name"
          value={name}
        />
        <select
          onChange={handleChange}
          name="tag"
          placeholder="tag"
          value={tag}
        >
          <option value="">Select Topic</option>
          <option value="networking">Networking</option>
          <option value="learning">Learning</option>
          <option value="deign-tools">Design Tools</option>
        </select>
        <input
          onChange={handleChange}
          type="text"
          name="description"
          placeholder="description"
          value={description}
        />
        {roundtableCreated ? (
          <button disabled type="submit">
            Roundtable created!
          </button>
        ) : (
          <button type="submit">Create Roundtable</button>
        )}
      </form>
    </div>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CreateRoundtablePage);
