import React from "react";
import { User, Discussion } from "types";
import { Header } from "components/Molecules/Header";

import { XIcon } from "constants/icons";
import UserCard from "components/Molecules/UserCard";
import { DiscussionItem } from "../DiscussionItem";

import "./index.scss";

interface MemberViewProps {
  onCloseSelectedMember: () => void;
  member: User;
  members: User[];
  discussions: Discussion[];
  onSelectMember: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    member: User,
  ) => void;
}

const MemberView: React.FC<MemberViewProps> = ({
  onCloseSelectedMember,
  member,
  members,
  discussions,
  onSelectMember,
}) => {
  const userDiscussions = discussions.filter(
    discussion => discussion.userId === member.uid,
  );

  return (
    <div className="member-view menu">
      <Header title="Member Profile">
        <span className="link" onClick={() => onCloseSelectedMember()}>
          <XIcon />
        </span>
      </Header>
      <div className="content">
        <UserCard user={member} />
        <section>
          <h3>Latest Discussions</h3>
          {userDiscussions.map((discussion: Discussion) => (
            <DiscussionItem
              key={discussion.uid}
              discussion={discussion}
              members={members}
              onSelectMember={onSelectMember}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default MemberView;
