import { Reducer } from "react";
import "firebase/firestore";

//import { List } from "immutable"
import {
  UsersState,
  UsersActionType,
  SUBSCRIBED_TO_USERS,
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "./types";
import { List } from "immutable";

export const usersReducer: Reducer<Partial<UsersState>, UsersActionType> = (
  state = new UsersState(),
  action: UsersActionType,
) => {
  switch (action.type) {
    case SUBSCRIBED_TO_USERS:
      return {
        ...state,
        usersSubscription: action.payload,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: List.of(...action.payload),
      };
    case GET_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return state;

    default:
      return state;
  }
};
