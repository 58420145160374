import React, { useMemo } from "react";
import { withAuthorization } from "containers/Auth";
import { Route, useRouteMatch } from "react-router-dom";
import RoundtableRoom from "./RoundtableRoom";
import { Roundtable } from "types";
import RoundtablesAppApi from "api/roundtablesAppApi";
import Loader from "components/Atoms/Loader";
import EditRoundtablePage from "./RoundtableEditPage";

interface RoundtableProps {
  roundtableId: string;
  roundtables: Roundtable[];
  roundtablesAppApi: RoundtablesAppApi;
}

const RoundtableRoute: React.FC<RoundtableProps> = ({
  roundtableId,
  roundtables,
  roundtablesAppApi,
}) => {
  const currentRoundtable = useMemo(
    () => roundtables.find(roundtable => roundtable.uid === roundtableId),
    [roundtables, roundtableId],
  );

  let { path } = useRouteMatch();

  if (!currentRoundtable || !roundtablesAppApi.authUser) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <div style={{ height: "100%" }}>
      <Route
        exact
        path={path}
        render={() => (
          <RoundtableRoom
            roundtable={currentRoundtable}
            roundtablesAppApi={roundtablesAppApi}
          />
        )}
      />
      <Route
        path={`${path}/edit`}
        render={() => (
          <EditRoundtablePage
            roundtableId={roundtableId}
            roundtable={currentRoundtable}
          />
        )}
      />
    </div>
  );
};

const condition = authUser => authUser?.approved;
export default withAuthorization(condition)(RoundtableRoute);
