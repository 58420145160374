import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../fb/context";
import { authApi } from "api/authApi";

const UserItem = ({ fbDb, match, location }) => {
  const [user, setUser] = useState(location.state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      if (user) {
        setLoading(false);
        return;
      }
      // TODO - should not be here - should dispatch an action
      const doc = await fbDb
        .collection("users")
        .doc(match.params.id)
        .get();
      if (doc.exists) {
        setUser(doc.data());
        setLoading(false);
      } else {
        console.log("User does not exist");
      }
    };
    getUser();
  }, [fbDb, match.params.id, user]);

  return (
    <div>
      <h2>User {match.params.id}</h2>
      {loading && <div>Loading ...</div>}
      {user && (
        <div>
          <p>
            <strong>ID:</strong> {user.uid}
          </p>
          <p>
            <strong>E-Mail:</strong> {user.email}
          </p>
          <p>
            <strong>Username:</strong> {user.username}
          </p>
          <button onClick={() => authApi.doResetPassword(user.email)}>
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
};

export default withFirebase(UserItem);
