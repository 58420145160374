import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../../../fb/context";

import * as ROUTES from "../../../constants/routes";
// import * as ROLES from "../../../constants/roles";

import "./index.scss";
import { User } from "types";
import { Button } from "components/Atoms/Button";
import usersApi from "api/usersApi";

// TODO: Use usersActions
const UserList = ({ fbDb }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const getUsers = async () => {
      const snap = await fbDb
        .collection("users")
        .orderBy("approved")
        .get();
      const newUsers = [];
      snap.forEach(user => {
        newUsers.push({
          ...user.data(),
          uid: user.id,
        });
      });
      setUsers(newUsers);
      setLoading(false);
    };
    getUsers();
  }, [fbDb]);

  return (
    <div className="user-list">
      <h2>Users</h2>
      <ul>
        {loading ? (
          <p>Loading...</p>
        ) : users.length > 0 ? (
          users.map((user: User) => (
            <li key={user.uid}>
              {/* {!!user.roles[ROLES.ADMIN] ? (
                <span role="img" aria-label="icon">
                  ⭐️
                </span>
              ) : null} */}
              <p>
                {user.username} {user.approved ? "✅" : null}
              </p>
              <p>{user.email}</p>
              <p>
                <strong>ID:</strong>
                {user.uid}
              </p>
              {user.portfolio ? <p>{user.portfolio}</p> : null}
              {user.intention ? (
                <p>
                  <strong>Intention:</strong>
                  {user.intention}
                </p>
              ) : null}
              {user.location ? (
                <p>
                  <strong>Location:</strong>
                  {user.location}
                </p>
              ) : null}
              {user.bio ? <p>{user.bio}</p> : null}
              <div className="actions">
                {user.approved ? (
                  <Button onClick={() => usersApi.unapproveUser(user.uid)}>
                    Unapprove
                  </Button>
                ) : (
                  <Button
                    onClick={() => usersApi.approveUser(user.uid)}
                    primary
                  >
                    Approve
                  </Button>
                )}
              </div>
              <span className="link">
                <Link
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`,
                    state: user,
                  }}
                >
                  Details
                </Link>
              </span>
            </li>
          ))
        ) : null}
      </ul>
    </div>
  );
};

export default withFirebase(UserList);
